<script lang="ts">
    import TradeItem from './TradeItem.svelte';

    import { onMount } from 'svelte';
    import type { IP2PTrade, IP2PTradeLiq, IUserInfo } from '../../Data/P2P';
    import { paymentMethods, getPhaseToStatus, RampPhases } from '../../Data/P2P';
    import BigNumber from 'bignumber.js';
    import { acceptTrade, cancelTrade, completeTrade, getP2pTrades, hasCompleted, rejectTrade, requestArbitration, submitConfirmation } from '../../API/P2P';
    import { XMTPLoadingProgress, getMessageCounts } from '../../API/Chat';
    import { ChainId, Wallet, WalletConnected } from '../../Data/Wallet';
    import { createEventDispatcher } from 'svelte';
    import { generateCoinSVG } from '../../API/utils/tokenImagerator';
    import { SkipTexts, Locale, TradeListTexts } from '../../Data/Locale';
    import subZero from '../../API/helpers/subZero';
    import autoAnimate from '../../API/helpers/autoAnimate';
    import { addActionLogLine } from '../../Data/ActionLogs';
    import { paymentMethodsMap } from '../../Data/P2P';
    import { fetchAddressTrades } from '../../API/helpers/fetchAddressTrades';
    import { chains } from '../../Data/Chains';
    import chainSwitcher from '../../API/helpers/chainSwitcher';
    import { Progressbar } from 'flowbite-svelte';
    import LoadBlaster from '../../components/Common/LoadBlaster.svelte';
    import { getCollateralTokenInfo, getAddressInfo, decollateralize } from '../../API/P2P';
    import type { IToken } from '../../Data/Tokens';
    import { Collateral } from '../../Data/P2PStore';
    import { USER_SETTINGS } from '../../Data/TransactionSettings';
    import Chat from './Chat.svelte';
    import { messageTrackerStore } from '../../Data/MessageCounts';

    export let tradeList: IP2PTradeLiq[];
    export let loading: boolean = false;
    export let arbitrationEnabled = false;
    export let userInfo: IUserInfo | undefined;
    // export let infoTextColor: string | undefined;

    let collateralToken: IToken | undefined;
    let pageNumber = 1;
    let loadingMoreTrades = false;
    let progressValue = 0;
    $: progressValue = $XMTPLoadingProgress;
    // $: console.log($XMTPLoadingProgress);

    const defaultTradeCounts: {[key: number]: number} = { 56: 0, 43114: 0, 1: 0, 369: 0, 109: 0 };

    let allActiveSkipscanTrades: {[key: number]: number} = defaultTradeCounts;
    let allInactiveSkipscanTrades: {[key: number]: number} = defaultTradeCounts;

    let showChatBox: boolean = false;
    let tradeChat: IP2PTradeLiq;
    let chatTarget: string;

    let updateTimeout: any;
    let updateInterval = 45000;

    const dispatch = createEventDispatcher();

    const getUserCollateral = async () => {
        loading = true;
        [userInfo, collateralToken] = await Promise.all([getAddressInfo($Wallet.address), getCollateralTokenInfo()]);
        console.log('getUserCollateral token', collateralToken);
        console.log('getUserCollateral collat amt', userInfo);
        loading = false;
    };

    const onDecollateralizeClick = async () => {
        if (!$WalletConnected) {
            console.error('no wallet connection :(');
            return;
        }

        if (!userInfo) return;

        if (+userInfo.tradeLocked != 0) {
            addActionLogLine('You cannot decollateralize while you have pending trades. Please cancel or complete any pending trades and try again.');
            return;
        }

        addActionLogLine(`Withdrawing collateral...`);

        const tx = await decollateralize();
        if (!tx) {
            console.error('decollat error');
        } else {
            // addActionLogLine(ActionLogTexts[$Locale].transaction_sent);
            dispatch('reload');
            getAddressInfo($Wallet.address).then((uinfo) => {
                if (!uinfo) return;
                userInfo = { ...uinfo };
            });
        }
    };

    const getLatestMessages = async () => {
        if (showChatBox) return;
        if (!tradeList) return;
        clearTimeout(updateTimeout);

        const activeTrades = tradeList.filter((trade) => trade.rampPhase != 1 && !(trade.rampPhase! >= 4));
        console.log('activeTrades: ', activeTrades);

        await getMessageCounts(activeTrades);

        activeTrades.forEach((trade) => {
            const convoId = `skipShift.tx_${trade.tradeId}_${trade.asset.chainId}`;
            trade.unreadMessages = $messageTrackerStore[convoId]?.newMessages || 0;
        });

        tradeList = [...tradeList];
        $messageTrackerStore = { ...$messageTrackerStore };
    };

    const loadNextTradePage = async () => {
        loadingMoreTrades = true;
        const moreTrades = await getP2pTrades($Wallet.address, pageNumber + 1);
        tradeList = [...new Map([...tradeList, ...moreTrades].map((trade) => [trade.tradeId, trade])).values()];
        console.log('tradeList 2 pages', tradeList);
        pageNumber++;
        loadingMoreTrades = false;
    };

    const onReload = () => {
        dispatch('reload');
    }

    onMount(async () => {
        console.log('tradeList: ', tradeList);

        collateralToken = await getCollateralTokenInfo();
        const skipscanTradeCounts = await fetchAddressTrades($Wallet.address);
        // console.log('activeTrades: ', skipscanTradeCounts?.active);
        // console.log('inactiveTrades: ', skipscanTradeCounts?.inactive);
        allActiveSkipscanTrades = skipscanTradeCounts?.active ?? defaultTradeCounts;
        allInactiveSkipscanTrades = skipscanTradeCounts?.inactive ?? defaultTradeCounts;

        getLatestMessages().then(() => {
            getMessageCounts(tradeList);
            updateTimeout = setTimeout(() => {
                getLatestMessages();
                getUserCollateral();
            }, updateInterval);
        });
    });

    function onOpenChat(v: any, trade: IP2PTradeLiq): void {
        console.log('onOpenChat v:', v);
        console.log('onOpenChat', trade);
        // dispatch('openChat', trade);
        tradeChat = trade;
        chatTarget = v.target;
    }
</script>

{#if showChatBox}
    <Chat on:close={(_) => (showChatBox = false)} order={tradeChat} target={chatTarget} />
{/if}

<!-- leave this shit on so the option is there all the time -->
<!-- {#if tradeList.filter((trade) => hasCompleted(trade)).length == 0} -->
<div class="w-full text-end pt-4 pr-4 text-sm flex text-gray-400">
    {#if allInactiveSkipscanTrades[$ChainId] > 0}
        <div class="grow basis-1/2 text-center infoText">
            Found {allInactiveSkipscanTrades[$ChainId]} completed trade(s).
        </div>
    {/if}
    <div class="w-fit m-auto mr-0 pl-3 text-end switchbox-highlight">
        <input class="rounded-full mr-1" type="checkbox" id="showCompletedTrades" bind:checked={$USER_SETTINGS.showInactive} />
        {TradeListTexts[$Locale].show_completed}
    </div>
</div>
<!-- {/if} -->

<div class="list-item-container min-h-[12em] max-h-[40em] h-full overflow-x-hidden overflow-y-auto flex flex-col items-center pt-4 pb-4" use:autoAnimate>
    {#each Object.entries(allActiveSkipscanTrades) as [key, trades]}
        {#if +key != $ChainId && trades > 0}
            <div class="flex pb-2 px-12 justify-between w-full">
                <span class=" pr-4 text-sm infoText"
                    >Found {trades} pending trade(s) on {chains[+key]?.nativeCurrency?.symbol}.</span>
                <button on:click={() => chainSwitcher(+key ||  1)} class=" text-sky-500 text-sm"
                    >Switch to {chains[+key]?.nativeCurrency?.symbol}</button>
            </div>
        {/if}
    {/each}

    {#if tradeList.length == 0}
        {#if loading}
            <div class="pending-holder flex">
                <div class="m-auto">
                    <div class="spinner m-auto" />
                    <span class="pt-4 pb-2 loading-text m-auto text-center w-full infoText">Loading trade list...</span>
                    <Progressbar color="blue" tweenDuration={1500} animate={true} progress={progressValue.toString()} />
                </div>
            </div>
        {:else}
            <div class="pending-holder">
                <i class="fad fa-exchange-alt text-9xl text-sky-200"></i>
                <span class="pt-4 mx-8 text-center text-gray-400">{SkipTexts[$Locale].no_trades_yet}</span>
            </div>
            <!-- <span class="pt-4">{SkipTexts[$Locale].no_trades_yet}</span> -->
        {/if}
    {:else if !$USER_SETTINGS.showInactive && tradeList.filter((trade) => !hasCompleted(trade.rampPhase)).length === 0}
        <div class="pending-holder">
            <i class="fad fa-exchange-alt text-9xl text-sky-200"></i>
            <span class="pt-4 mx-8 text-center text-gray-400" class:hidden={$USER_SETTINGS.showInactive}>{SkipTexts[$Locale].no_trades_yet}</span>
            {#if userInfo && collateralToken && BigNumber(userInfo.collateral).gt(0) && +userInfo.tradeLocked == 0}
                <div class="flex flex-col pt-2">
                    <button on:click={onDecollateralizeClick} class="p-4 {+userInfo.tradeLocked != 0 ? 'bg-gray-500' : 'buttonGradient'} flex flex-col rounded-lg">
                        <span class="text-white">{SkipTexts[$Locale].decollateralize}</span>
                        <span class="text-white text-xs"
                            >{BigNumber(userInfo?.collateral).div(10 ** (collateralToken?.decimals || 18))} {collateralToken.symbol}</span>
                    </button>
                </div>
            {/if}
        </div>
    {:else if tradeList.length !== 0}
        {#each tradeList as trade, tradeIndex}
            <TradeItem {trade} {tradeIndex} {arbitrationEnabled} bind:showChatBox on:reload={onReload} on:openChat={(v) => onOpenChat(v.detail, trade)}></TradeItem>
        {/each}

        <div class="flex w-full">
            {#if loadingMoreTrades}
                <LoadBlaster showLoad={true} />
            {:else if userInfo && +userInfo.tradeIndex > tradeList.length}
                <button on:click={loadNextTradePage} class="tradeListText m-auto">{$USER_SETTINGS.showInactive ? 'Load more trades...' : 'Refresh trades'}</button>
            {/if}
        </div>
    {/if}
</div>

<style lang="scss">
    @keyframes bounce {
        0%,
        100% {
            transform: translateY(-25%) scale(1.1);
            animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
        }
        50% {
            transform: translateY(0) scale(0.9);
            animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
        }
    }

    @keyframes tiny-bounce {
        0%,
        100% {
            transform: translateY(-15%);
            animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
        }
        50% {
            transform: translateY(0);
            animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
        }
    }
    .pending-holder {
        display: flex;
        flex-direction: column;
        height: 90%;
        align-items: center;
        justify-content: center;
        width: 100%;
    }
    .switchbox-highlight {
        border-left-width: 10px;
        border-color: var(--border-color);
        border-style: double;
    }
    .action-section {
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }
    .action-button {
        border-radius: 8px;
        background: linear-gradient(90deg, #0ea5e9 0%, rgba(14, 165, 233, 0.75) 100%);
        padding: 5px 10px;
        font-size: 0.85rem;
        box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.25);
        border: 2px rgb(70 126 239 / 10%) solid;
        animation: tiny-bounce 1s infinite;
    }

    // .action-button:hover {
    //     transform: scale(1.05);
    // }
    .arbitration-button {
        border-radius: 8px;
        background: linear-gradient(90deg, #ffa500 0%, rgba(255, 165, 0, 0.75) 100%);
        padding: 10px 16px;
    }
    .loading-text {
        /* font-family: Inter; */
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px; /* 120% */
    }
    .venue-icon {
        width: 2em;
    }
    .trade-item {
        width: 95%;
        padding: 16px;
        border-radius: 8px;
        background: var(--off-white, #f2f2f2);
        box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
        border: 1px #0000001f solid;
    }
    .venue-container {
        border-radius: 8px;
        background: #fff;
        box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
    }

    .spinner {
        width: 4em;
        height: 4em;
        border: 0.5em solid rgba(0, 0, 0, 0.1);
        border-left-color: #0ea5e9;
        border-radius: 50%;
        animation: spin 1s linear infinite;
    }

    .progress-bar {
        width: 100%;
        height: 10px;
        background-color: #bbb8b8; /* This is the gray background */
        border-radius: 5px;
    }

    .progress-bar-fill {
        height: 10px;
        background-color: #4caf50; /* This is the color of the filled part */
        border-radius: 5px;
    }

    @keyframes spin {
        to {
            transform: rotate(360deg);
        }
    }

    @keyframes shake {
        0% {
            transform: translateX(0);
        }
        25% {
            transform: translateX(-2.5px);
        }
        50% {
            transform: translateX(2.5px);
        }
        75% {
            transform: translateX(-2.5px);
        }
        100% {
            transform: translateX(0);
        }
    }

    .shake-on-hover:hover {
        animation: shake 0.25s;
    }
</style>
