import { writable } from 'svelte/store';

export const Locale = writable('en');

export type SupportedLanguages = 'pt' | 'es' | 'en' | 'cz' | 'bn';
const supportedLanguages: SupportedLanguages[] = ['pt', 'es', 'en', 'cz', 'bn'];
export const DefaultLanguage: SupportedLanguages = 'en';

export const isSupportedLanguage = (lang: string): lang is SupportedLanguages => {
  return (supportedLanguages as string[]).includes(lang);
};

interface SkipTexts {
  buy_crypto: string;
  sell_crypto: string;
  my_trades: string;
  my_listings: string;
  add_liquidity: string;
  settings: string;
  selected_network: string;
  you_pay: string;
  you_receive: string;
  payment_venue_id: string;
  enter_venue_id: string;
  rate: string;
  select_venue: string;
  search_venue: string;
  select_token: string;
  select_fiat: string;
  token_list: string;
  currency_list: string;
  available_venues: string;
  no_trades_yet: string;
  no_listings_found: string;
  connect_wallet: string;
  requires_collateral: string;
  autocollat_warning: string;
  autocollat_warning_2: string;
  autocollat_warning_3: string;
  no_collat_required: string;
  no_collat_required_id_inputs: string;
  score: string;
  pending_trade_warning: string;
  pending_trade_warning_2: string;
  select_both_message: string;
  post_submit_message_on: string;
  post_submit_message_off: string;
  information: string;
  show_inactive_trades: string,
  provider_mode: string,
  verify_wallet: string;
  select_assets: string;
  submit_trade: string;
  decollateralize: string;
  submitting_trade: string;
  try_another_chain: string;
  enter_kyp_details: string;
  minimum_not_met: string;
  not_enough_liquidity: string;
  minmax_notif_1: string;
  and: string;
  on: string;
  trades_between: string;
  minmax_notif_2: string;
  view_more_rates: string;
}

interface TradeListTexts {
  pending_trades_msg: string;
  pending_trades_msg_2: string;
  show_completed: string;
  switch_to_network:string;
  status: string;
  chatting_with_client: string;
  message_history: string;
  search_currencies: string;
  search_tokens: string;
  select_venue: string;
  peer_id: string;
  loading_trade_list: string;
  send: string;
  on: string;
  onramp_submitconf_user_message_1: string;
  onramp_submitconf_user_message_2: string;
  onramp_submitconf_user_message_3: string;
  onramp_init_user_message_1: string;
  onramp_init_user_message_2: string;
  onramp_init_user_message_3: string;
  kyp_details: string;
}

interface TradeActionButtonTexts {
  waiting: string,
  accept: string,
  rejected: string,
  conf_number: string,
  complete: string,
  trade_complete: string,
  cancelled: string
}

interface TradeStatuses {
  acceptancePending: string;
  rejected: string;
  awaitingPayment: string;
  paymentConfirmationSent: string;
  completed: string;
  cancelled: string;
  cancelReqMaker: string;
  cancelReqTaker: string;
  arbitrationMaker: string;
  arbitrationTaker: string;
  settledMaker: string;
  settledTaker: string;
  settledSplit: string;
}

interface MenuTexts {
  resources: string;
  features: string;
  alerts: string;
  integrate: string;
  documentation: string;
  products: string;
  partners: string;
  venues: string;
  about: string;
  subscribe: string;
  skip_limits: string;
  more_info: string;
  all_rights_reserved: string;
}

interface PageTexts {
  skip_the_questions: string;
  shift_control: string;
  homepage_top_desc: string;
  homepage_bottom_desc: string;
  what_weve_built: string;
  products_desc: string;
  global_payment_methods: string;
  liquidity_provision: string;
  risk_mitigation: string;
  toolkit: string;
  global_payment_methods_desc: string;
  liquidity_provision_desc: string;
  risk_mitigation_desc: string;
  toolkit_desc: string;
  partners_title: string;
  partners_desc: string;
  and_more: string;
  methods_title: string;
  methods_desc: string;
  available_on: string;
  integrate: string;
  faq_left_title: string;
  faq_left_desc: string;
  faq_left_sub_desc: string;
  faq_right_title: string;
  subscribe_title: string;
  subscribe: string;
}

interface FAQTexts {
  what_is_title: string;
  what_is_desc: string;
  how_to_onramp_title: string;
  how_to_onramp_desc: string;
  how_to_offramp_title: string;
  how_to_offramp_desc: string;
  collateral_requirements_title: string;
  collateral_requirements_desc: string;
  provider_scoring_title: string;
  provider_scoring_desc: string;
  face_issues_title: string;
  face_issues_desc: string;
  skipshift_token_title: string;
  skipshift_token_desc: string;
}

interface AddLiqTexts {
  onramp: string;
  offramp: string;
  select_token: string;
  select_currency: string;
  spread: string;
  require_collateral: string;
  min: string;
  max: string;
  taker: string;
  onramp_tt: string;
  offramp_tt: string;
  spread_tt_1: string;
  above: string;
  below: string;
  spread_tt_2: string;
  for: string;
  spread_tt_3: string;
  req_collateral_tt: string;
  does_not_req_collateral_tt: string;
  min_tt_1: string;
  crypto: string;
  min_tt_2: string;
  max_tt_1: string;
  max_tt_2: string;
  taker_tt: string;
  kyp_tt: string;
  collat_warning_1: string;
  collat_warning_2: string;
  spread_limit_warning: string;
  has_id_fields_msg: string;
  require_kyp: string;
  submit_liquidity: string;
}

interface ActionLogTexts {
  getting_latest: string;
  encrypting_payment_info: string;
  processing_transaction: string;
  collateral_withdrawn: string;
  payment_info_sent: string;
  creating_p2p_trade: string;
  trade_failed_retry: string;
  transaction_sent: string;
  transaction_rejected: string;
  error_encountered: string;
  rpc_janky_checking: string;
  transaction_failed: string;
  arbitration_requested: string;
  submitting_payment_confirmation: string;
  sending_encrypted_confirmation: string;
  encrypted_confirmation_sent: string;
  could_not_submit_encrypted_payload: string;
  payment_confirmed_on_chain: string;
  payment_confirmation_failed: string;
  confirmation_failed_check_wallet: string;
  completing_trade: string;
  p2p_trade_complete: string;
  trade_completion_failed_check_wallet: string;
  trade_complete: string;
  p2p_trade_initiated: string;
  p2p_trade_failed: string;
  trade_submission_failed_check_wallet: string;
  approving_x: string;
  x_approved: string;
  approving_collateral_token: string;
  collateral_token_approved: string;
  approval_failed_check_wallet: string;
  disabling_liquidity: string;
  p2p_liquidity_status_change: string;
  insufficient_funds_for_trade_1: string;
  insufficient_funds_for_trade_2: string;
  not_connected_to_wallet: string;
  missing_required_data_for_trade: string;
  missing_node_connection: string;
  missing_some_trade_data: string;
  could_not_get_collateral_quote: string;
  approval_failed_retry: string;
  could_not_build_payload: string;
  activated: string;
  paused: string;
  select_token: string;
  enter_min: string;
  enter_max: string;
  enter_spread: string;
  lower_spread: string;
  select_currency: string;
  select_venues: string;
  blank_kyp_warning: string;
  liq_created: string;
  alert_connect_warning: string;
}

interface ModalTexts {
  alerts_modal_title: string;
  alerts_modal_desc: string;
  alerts_modal_confirm: string;
}
export interface ISkipTexts {
  [keys: string]: SkipTexts;
}

export interface IPageTexts {
  [keys: string]: PageTexts;
}

export interface IFAQTexts {
  [keys: string]: FAQTexts;
}
export interface IMenuTexts {
  [keys: string]: MenuTexts;
}

export interface ITradeStatuses {
  [keys: string]: TradeStatuses;
}

export interface IAddLiqTexts {
  [keys: string]: AddLiqTexts;
}

export interface ITradeListTexts {
  [keys: string]: TradeListTexts;
}

export interface IActionLogTexts {
  [keys: string]: ActionLogTexts;
}

export interface IModalTexts {
  [keys: string]: ModalTexts;
}

export interface ITradeActionButtonTexts {
  [keys: string]: TradeActionButtonTexts;
}

export const ActionLogTexts: IActionLogTexts = {
  en: {
    getting_latest: "Getting latest trades and liquidity...",
    encrypting_payment_info: "Encrypting payment info...",
    processing_transaction: "Processing the transaction...",
    collateral_withdrawn: "Collateral withdrawn!",
    payment_info_sent: "Payment info sent!",
    creating_p2p_trade: "Creating new P2P Trade",
    trade_failed_retry: "Trade failed, please try again!",
    transaction_sent: "Transaction sent!",
    transaction_rejected: "Transaction Rejected...",
    error_encountered: "Error encountered 🙁",
    rpc_janky_checking: "RPC Janky, double checking tx...",
    transaction_failed: "Transaction failed 🙁",
    arbitration_requested: "Arbitration Requested!",
    submitting_payment_confirmation: "Submitting payment confirmation...",
    sending_encrypted_confirmation:
      "Sending encrypted confirmation to counterparty...",
    encrypted_confirmation_sent: "Encrypted confirmation sent!",
    could_not_submit_encrypted_payload:
      "Could not submit encrypted payload, please refresh and try again!",
    payment_confirmed_on_chain: "Payment confirmed on-chain!",
    payment_confirmation_failed: "Payment confirmation failed 🙁",
    confirmation_failed_check_wallet:
      "Confirmation failed! Please double-check connected wallet and/or refresh.",
    completing_trade: "Completing trade...",
    p2p_trade_complete: "P2P trade complete!",
    trade_completion_failed_check_wallet:
      "Trade completion failed! Please double-check connected wallet and/or refresh.",
    trade_complete: "Trade complete!",
    p2p_trade_initiated: "P2P trade initiated!",
    p2p_trade_failed: "P2P trade failed 🙁",
    trade_submission_failed_check_wallet:
      "Trade submission failed! Please double-check connected wallet and/or refresh.",
    approving_x: "Approving ",
    x_approved: " Approved!",
    approving_collateral_token: "Approving collateral token",
    collateral_token_approved: "Collateral token Approved!",
    approval_failed_check_wallet:
      "Approval failed! Please double-check connected wallet and/or refresh.",
    disabling_liquidity: "Disabling liquidity...",
    p2p_liquidity_status_change: "P2P liquidity ",
    insufficient_funds_for_trade_1: "You do not have enough ",
    insufficient_funds_for_trade_2: " in your wallet to accept this trade!",
    not_connected_to_wallet:
      "Not connected to a wallet. Please check your wallet connection.",
    missing_required_data_for_trade:
      "Missing required data for trade submission.",
    missing_node_connection:
      "Missing node connection, please refresh and try again!",
    missing_some_trade_data:
      "Missing some trade data, please refresh and try again!",
    could_not_get_collateral_quote:
      "Could not get collateral quote, please refresh and try again in a few seconds.",
    approval_failed_retry: "Approval failed, please try again!",
    could_not_build_payload:
      "Could not build payload, please refresh and try again!",
    activated: "activated!",
    paused: "paused!",
    select_token: 'Please select a token',
    enter_min: 'Please enter a min',
    enter_max: 'Please enter a max',
    enter_spread: 'Please enter a spread',
    lower_spread: 'Please lower your spread to be below ',
    select_currency: 'Please select a currency',
    select_venues: 'Please select at least one payment venue',
    blank_kyp_warning: 'You cannot have blank KYP option fields. Please fill them out or toggle the option off to disable KYP requirements.',
    liq_created: 'P2P liquidity created! The listing will be available in 2-3 minutes.',
    alert_connect_warning: 'You must connect your wallet to register for alerts.'
  },
  es: {
    getting_latest: "Obteniendo las últimas operaciones y liquidez...",
    encrypting_payment_info: "Encriptando información de pago...",
    processing_transaction: "Procesando transacción...",
    collateral_withdrawn: "¡Colateral retirado!",
    payment_info_sent: "¡Información de pago enviada!",
    creating_p2p_trade: "Creando nuevo trade P2P",
    trade_failed_retry: "¡El intercambio falló, por favor inténtalo de nuevo!",
    transaction_sent: "¡Transacción enviada!",
    transaction_rejected: "Transacción rechazada...",
    error_encountered: "Error encontrado 🙁",
    rpc_janky_checking: "RPC lento, verificando la transacción...",
    transaction_failed: "¡Transacción fallida 🙁",
    arbitration_requested: "¡Se solicitó arbitraje!",
    submitting_payment_confirmation: "Enviando confirmación de pago...",
    sending_encrypted_confirmation:
      "Enviando confirmación encriptada a contraparte...",
    encrypted_confirmation_sent: "¡Confirmación encriptada enviada!",
    could_not_submit_encrypted_payload:
      "No se pudo enviar el dato encriptado, por favor actualiza e inténtalo de nuevo",
    payment_confirmed_on_chain: "¡Pago confirmado en cadena!",
    payment_confirmation_failed: "¡Falló la confirmación de pago 🙁",
    confirmation_failed_check_wallet:
      "¡Confirmación fallida! Por favor verifica la billetera conectada y/o actualiza.",
    completing_trade: "Completando intercambio...",
    p2p_trade_complete: "¡Intercambio P2P completado!",
    trade_completion_failed_check_wallet:
      "¡Falló la finalización del intercambio! Por favor verifica la billetera conectada y/o actualiza.",
    trade_complete: "¡Trade completado!",
    p2p_trade_initiated: "¡Trade P2P iniciado!",
    p2p_trade_failed: "¡Falló el trade P2P 🙁",
    trade_submission_failed_check_wallet:
      "¡Falló la emisión del intercambio! Por favor verifica la billetera conectada y/o actualiza.",
    approving_x: "Aprobando ",
    x_approved: " aprobado!",
    approving_collateral_token: "Aprobando token de garantía",
    collateral_token_approved: "¡Token Colateral aprobado!",
    approval_failed_check_wallet:
      "¡Falló la aprobación! Por favor verifica la billetera conectada y/o actualiza.",
    disabling_liquidity: "Desactivando liquidez...",
    p2p_liquidity_status_change: "¡Liquidez P2P ",
    insufficient_funds_for_trade_1: "¡No tienes suficiente ",
    insufficient_funds_for_trade_2:
      " en tu billetera para aceptar este intercambio!",
    not_connected_to_wallet:
      "No conectado a una billetera. Por favor verifica la conexión de tu billetera.",
    missing_required_data_for_trade:
      "Faltan datos requeridos para la el trade.",
    missing_node_connection:
      "Fallo en la conexión de nodo, por favor actualiza e inténtalo de nuevo.",
    missing_some_trade_data:
      "Faltan algunos datos del trade, por favor actualiza e inténtalo de nuevo.",
    could_not_get_collateral_quote:
      "No se pudo obtener la cotización del colateral, por favor actualiza e inténtalo de nuevo en unos segundos.",
    approval_failed_retry: "La aprobación falló, por favor inténtalo de nuevo.",
    could_not_build_payload:
      "No se pudo construir el dato, por favor actualiza e inténtalo de nuevo.",
    activated: "activada",
    paused: "pausada",
    select_token: 'Por favor, selecciona un token',
    enter_min: 'Por favor, ingresa un mínimo',
    enter_max: 'Por favor, ingresa un máximo',
    enter_spread: 'Por favor, ingresa un margen',
    lower_spread: 'Por favor, reduce tu margen para que sea inferior al ',
    select_currency: 'Por favor, selecciona una moneda',
    select_venues: 'Por favor, selecciona al menos un lugar de pago',
    blank_kyp_warning: 'No puedes tener campos de opción KYP en blanco. Por favor, complétalos o desactiva la opción para deshabilitar los requisitos de KYP.',
    liq_created: '¡Liquidez P2P creada! La lista estará disponible en 2-3 minutos.',
    alert_connect_warning: 'Debes conectar tu billetera para registrarte en las alertas.'
  },
  cz: {
    getting_latest: "Getting latest trades and liquidity...",
    encrypting_payment_info: "Encrypting payment info...",
    processing_transaction: "Processing the transaction...",
    collateral_withdrawn: "Collateral withdrawn!",
    payment_info_sent: "Payment info sent!",
    creating_p2p_trade: "Creating new P2P Trade",
    trade_failed_retry: "Trade failed, please try again!",
    transaction_sent: "Transaction sent!",
    transaction_rejected: "Transaction Rejected...",
    error_encountered: "Error encountered 🙁",
    rpc_janky_checking: "RPC Janky, double checking tx...",
    transaction_failed: "Transaction failed 🙁",
    arbitration_requested: "Arbitration Requested!",
    submitting_payment_confirmation: "Submitting payment confirmation...",
    sending_encrypted_confirmation:
      "Sending encrypted confirmation to counterparty...",
    encrypted_confirmation_sent: "Encrypted confirmation sent!",
    could_not_submit_encrypted_payload:
      "Could not submit encrypted payload, please refresh and try again!",
    payment_confirmed_on_chain: "Payment confirmed on-chain!",
    payment_confirmation_failed: "Payment confirmation failed 🙁",
    confirmation_failed_check_wallet:
      "Confirmation failed! Please double-check connected wallet and/or refresh.",
    completing_trade: "Completing trade...",
    p2p_trade_complete: "P2P trade complete!",
    trade_completion_failed_check_wallet:
      "Trade completion failed! Please double-check connected wallet and/or refresh.",
    trade_complete: "Trade complete!",
    p2p_trade_initiated: "P2P trade initiated!",
    p2p_trade_failed: "P2P trade failed 🙁",
    trade_submission_failed_check_wallet:
      "Trade submission failed! Please double-check connected wallet and/or refresh.",
    approving_x: "Approving ",
    x_approved: " Approved!",
    approving_collateral_token: "Approving collateral token",
    collateral_token_approved: "Collateral token Approved!",
    approval_failed_check_wallet:
      "Approval failed! Please double-check connected wallet and/or refresh.",
    disabling_liquidity: "Disabling liquidity...",
    p2p_liquidity_status_change:
      "P2P liquidity ${activate ? 'activated' : 'paused'}!",
    insufficient_funds_for_trade_1: "You do not have enough ",
    insufficient_funds_for_trade_2: " in your wallet to accept this trade!",
    not_connected_to_wallet:
      "Not connected to a wallet. Please check your wallet connection.",
    missing_required_data_for_trade:
      "Missing required data for trade submission.",
    missing_node_connection:
      "Missing node connection, please refresh and try again!",
    missing_some_trade_data:
      "Missing some trade data, please refresh and try again!",
    could_not_get_collateral_quote:
      "Could not get collateral quote, please refresh and try again in a few seconds.",
    approval_failed_retry: "Approval failed, please try again!",
    could_not_build_payload:
      "Could not build payload, please refresh and try again!",
    activated: "activated!",
    paused: "paused!",
    select_token: 'Please select a token',
    enter_min: 'Please enter a min',
    enter_max: 'Please enter a max',
    enter_spread: 'Please enter a spread',
    lower_spread: 'Please lower your spread to be below ',
    select_currency: 'Please select a currency',
    select_venues: 'Please select at least one payment venue',
    blank_kyp_warning: 'You cannot have blank KYP option fields. Please fill them out or toggle the option off to disable KYP requirements.',
    liq_created: 'P2P liquidity created! The listing will be available in 2-3 minutes.',
    alert_connect_warning: 'You must connect your wallet to register for alerts.'
  },
  pt: {
    getting_latest: "Getting latest trades and liquidity...",
    encrypting_payment_info: "Encrypting payment info...",
    processing_transaction: "Processing the transaction...",
    collateral_withdrawn: "Collateral withdrawn!",
    payment_info_sent: "Payment info sent!",
    creating_p2p_trade: "Creating new P2P Trade",
    trade_failed_retry: "Trade failed, please try again!",
    transaction_sent: "Transaction sent!",
    transaction_rejected: "Transaction Rejected...",
    error_encountered: "Error encountered 🙁",
    rpc_janky_checking: "RPC Janky, double checking tx...",
    transaction_failed: "Transaction failed 🙁",
    arbitration_requested: "Arbitration Requested!",
    submitting_payment_confirmation: "Submitting payment confirmation...",
    sending_encrypted_confirmation:
      "Sending encrypted confirmation to counterparty...",
    encrypted_confirmation_sent: "Encrypted confirmation sent!",
    could_not_submit_encrypted_payload:
      "Could not submit encrypted payload, please refresh and try again!",
    payment_confirmed_on_chain: "Payment confirmed on-chain!",
    payment_confirmation_failed: "Payment confirmation failed 🙁",
    confirmation_failed_check_wallet:
      "Confirmation failed! Please double-check connected wallet and/or refresh.",
    completing_trade: "Completing trade...",
    p2p_trade_complete: "P2P trade complete!",
    trade_completion_failed_check_wallet:
      "Trade completion failed! Please double-check connected wallet and/or refresh.",
    trade_complete: "Trade complete!",
    p2p_trade_initiated: "P2P trade initiated!",
    p2p_trade_failed: "P2P trade failed 🙁",
    trade_submission_failed_check_wallet:
      "Trade submission failed! Please double-check connected wallet and/or refresh.",
    approving_x: "Approving ",
    x_approved: " Approved!",
    approving_collateral_token: "Approving collateral token",
    collateral_token_approved: "Collateral token Approved!",
    approval_failed_check_wallet:
      "Approval failed! Please double-check connected wallet and/or refresh.",
    disabling_liquidity: "Disabling liquidity...",
    p2p_liquidity_status_change:
      "P2P liquidity ${activate ? 'activated' : 'paused'}!",
    insufficient_funds_for_trade_1: "You do not have enough ",
    insufficient_funds_for_trade_2: " in your wallet to accept this trade!",
    not_connected_to_wallet:
      "Not connected to a wallet. Please check your wallet connection.",
    missing_required_data_for_trade:
      "Missing required data for trade submission.",
    missing_node_connection:
      "Missing node connection, please refresh and try again!",
    missing_some_trade_data:
      "Missing some trade data, please refresh and try again!",
    could_not_get_collateral_quote:
      "Could not get collateral quote, please refresh and try again in a few seconds.",
    approval_failed_retry: "Approval failed, please try again!",
    could_not_build_payload:
      "Could not build payload, please refresh and try again!",
    activated: "activated!",
    paused: "paused!",
    select_token: 'Please select a token',
    enter_min: 'Please enter a min',
    enter_max: 'Please enter a max',
    enter_spread: 'Please enter a spread',
    lower_spread: 'Please lower your spread to be below ',
    select_currency: 'Please select a currency',
    select_venues: 'Please select at least one payment venue',
    blank_kyp_warning: 'You cannot have blank KYP option fields. Please fill them out or toggle the option off to disable KYP requirements.',
    liq_created: 'P2P liquidity created! The listing will be available in 2-3 minutes.',
    alert_connect_warning: 'You must connect your wallet to register for alerts.'
  },
  bn: {
    getting_latest: "সর্বশেষ লেনদেন এবং তরলতা সংগ্রহ করা হচ্ছে...",
    encrypting_payment_info: "পেমেন্টের তথ্য এনক্রিপ্ট করা হচ্ছে...",
    processing_transaction: "লেনদেন প্রক্রিয়াকরণ করা হচ্ছে...",
    collateral_withdrawn: "জামানত তুলে নেওয়া হয়েছে!",
    payment_info_sent: "পেমেন্টের তথ্য পাঠানো হয়েছে!",
    creating_p2p_trade: "নতুন P2P লেনদেন তৈরি করা হচ্ছে",
    trade_failed_retry: "লেনদেন ব্যর্থ হয়েছে, অনুগ্রহ করে আবার চেষ্টা করুন!",
    transaction_sent: "লেনদেন পাঠানো হয়েছে!",
    transaction_rejected: "লেনদেন প্রত্যাখ্যাত হয়েছে...",
    error_encountered: "ত্রুটি ঘটেছে 🙁",
    rpc_janky_checking: "RPC সমস্যা, লেনদেন পুনরায় যাচাই করা হচ্ছে...",
    transaction_failed: "লেনদেন ব্যর্থ হয়েছে 🙁",
    arbitration_requested: "পূর্বনির্ধারণ জন্য অনুরোধ করা হয়েছে!",
    submitting_payment_confirmation: "পেমেন্ট নিশ্চিতকরণের জন্য জমা দেওয়া হচ্ছে...",
    sending_encrypted_confirmation: "প্রতিপক্ষকে এনক্রিপ্টেড নিশ্চিতকরণের জন্য পাঠানো হচ্ছে...",
    encrypted_confirmation_sent: "এনক্রিপ্টেড নিশ্চিতকরণের জন্য পাঠানো হয়েছে!",
    could_not_submit_encrypted_payload: "এনক্রিপ্টেড পেইলোড জমা দেওয়া সম্ভব হয়নি, অনুগ্রহ করে রিফ্রেশ করে আবার চেষ্টা করুন!",
    payment_confirmed_on_chain: "পেমেন্ট চেইনে নিশ্চিত হয়েছে!",
    payment_confirmation_failed: "পেমেন্ট নিশ্চিতকরণে ব্যর্থ হয়েছে 🙁",
    confirmation_failed_check_wallet: "নিশ্চিতকরণ ব্যর্থ হয়েছে! অনুগ্রহ করে সংযুক্ত ওয়ালেট ডাবল-চেক করুন অথবা রিফ্রেশ করুন।",
    completing_trade: "লেনদেন সম্পন্ন করা হচ্ছে...",
    p2p_trade_complete: "P2P লেনদেন সম্পন্ন হয়েছে!",
    trade_completion_failed_check_wallet: "লেনদেন সম্পন্ন করা সম্ভব হয়নি! অনুগ্রহ করে সংযুক্ত ওয়ালেট ডাবল-চেক করুন অথবা রিফ্রেশ করুন।",
    trade_complete: "লেনদেন সম্পন্ন হয়েছে!",
    p2p_trade_initiated: "P2P লেনদেন শুরু হয়েছে!",
    p2p_trade_failed: "P2P লেনদেন ব্যর্থ হয়েছে 🙁",
    trade_submission_failed_check_wallet: "লেনদেন জমা দেওয়া সম্ভব হয়নি! অনুগ্রহ করে সংযুক্ত ওয়ালেট ডাবল-চেক করুন অথবা রিফ্রেশ করুন।",
    approving_x: "অনুমোদন হচ্ছে ",
    x_approved: " অনুমোদিত!",
    approving_collateral_token: "গচ্ছিত টোকেন অনুমোদন করা হচ্ছে",
    collateral_token_approved: "গচ্ছিত টোকেন অনুমোদিত!",
    approval_failed_check_wallet: "অনুমোদনে ব্যর্থ হয়েছে! অনুগ্রহ করে সংযুক্ত ওয়ালেট ডাবল-চেক করুন অথবা রিফ্রেশ করুন।",
    disabling_liquidity: "তরলতা অক্ষম করা হচ্ছে...",
    p2p_liquidity_status_change: "P2P তরলতার অবস্থান পরিবর্তন হচ্ছে ",
    insufficient_funds_for_trade_1: "আপনার ওয়ালেটে যথেষ্ট তহবিল নেই",
    insufficient_funds_for_trade_2: "আপনার ওয়ালেটে যথেষ্ট তহবিল নেই",
    not_connected_to_wallet: "ওয়ালেটের সাথে সংযুক্ত নয়। দয়া করে আপনার ওয়ালেট সংযোগ আছে কিনা পরীক্ষা করুন।",
    missing_required_data_for_trade: "লেনদেন জমার জন্য প্রয়োজনীয় ডেটা অনুপস্থিত।",
    missing_node_connection: "নোড সংযোগ অনুপস্থিত, দয়া করে রিফ্রেশ করে আবার চেষ্টা করুন!",
    missing_some_trade_data: "কিছু লেনদেনের ডেটা অনুপস্থিত, দয়া করে রিফ্রেশ করে আবার চেষ্টা করুন!",
    could_not_get_collateral_quote: "জমার কোট পাওয়া যায়নি, দয়া করে কয়েক সেকেন্ড পর আবার চেষ্টা করুন।",
    approval_failed_retry: "অনুমোদন ব্যর্থ হয়েছে, অনুগ্রহ করে আবার চেষ্টা করুন!",
    could_not_build_payload: "পেইলোড তৈরি করা যায়নি, দয়া করে রিফ্রেশ করে আবার চেষ্টা করুন!",
    activated: "সক্রিয় হয়েছে!",
    paused: "বিরত হয়েছে!",
    select_token: 'টোকেন নির্বাচন করুন',
    enter_min: 'ন্যূনতম এন্ট্রির পরিমান লিখুন',
    enter_max: 'সর্বোচ্চ এন্ট্রির পরিমান লিখুন',
    enter_spread: 'স্প্রেড রেটের পরিমান লিখুন',
    lower_spread: 'আপনার স্প্রেড রেট কমান ',
    select_currency: 'মুদ্রা নির্বাচন করুন',
    select_venues: 'পেমেন্ট ভেন্যু নির্বাচন করুন',
    blank_kyp_warning: 'আপনার KYP অপশনের ক্ষেত্রগুলির মধ্যে খালি থাকতে পারে না। দয়া করে এগুলি পূরণ করুন অথবা KYP প্রয়োজনীয়তা অক্ষম করতে বিকল্পটি টগল করুন।',
    liq_created: 'P2P তারল্য তৈরি হয়েছে! তালিকাটি ২-৩ মিনিটের মধ্যে উপলব্ধ হবে।',
    alert_connect_warning: 'আপনাকে সতর্কতার জন্য নিবন্ধন করতে আপনার ওয়ালেট সংযুক্ত করতে হবে।'

  }
};

export const TradeListTexts: ITradeListTexts = {
  'en': {
    pending_trades_msg: "Found ",
    pending_trades_msg_2: "pending trades on ",
    show_completed: "show completed",
    switch_to_network: "Switch to",
    status: "Status",
    chatting_with_client: "Chatting with ramp client",
    message_history: "Message history",
    search_currencies: "Search Currencies",
    search_tokens: "Search Tokens",
    select_venue: "Select Venue",
    peer_id: "Peer id:",
    loading_trade_list: "Loading trade list",
    send: "Send",
    on: 'on',
    onramp_submitconf_user_message_1: ' now to ',
    onramp_submitconf_user_message_2: 'Afterwards, check the chat for any messages from the liquidity provider.',
    onramp_submitconf_user_message_3: '',
    onramp_init_user_message_1: 'Awaiting trade acceptance. Please check the chat for any unread messages from the provider.',
    onramp_init_user_message_2: "Once accepted, the provider's ",
    onramp_init_user_message_3: ' ID will appear below.',
    kyp_details: 'KYP Details:'
  },
  'es': {
    pending_trades_msg: "Se encontraron ",
    pending_trades_msg_2: " intercambios pendientes en ",
    show_completed: "mostrar completados",
    switch_to_network: "Cambiar a ",
    status: "Estado",
    chatting_with_client: "Chateando con el cliente",
    message_history: "Historial de mensajes",
    search_currencies: "Buscar monedas",
    search_tokens: "Buscar Tokens",
    select_venue: "Seleccionar lugar",
    peer_id: "ID del par",
    loading_trade_list: "Cargando lista de trades",
    send: "Envie",
    on: 'en',
    onramp_submitconf_user_message_1: ' ahora a ',
    onramp_submitconf_user_message_2: 'Despues, revise el chat para comprobar mensajes con el proveedor.',
    onramp_submitconf_user_message_3: '',
    onramp_init_user_message_1: 'Esperando aceptación del trade. Por favor revise el chat con el proveedor.',
    onramp_init_user_message_2: "Una vez aceptado el trade, la ",
    onramp_init_user_message_3: ' ID del proveedor aparecerá debajo.',
    kyp_details: 'Detalles de KYC:'
  },
  'cz': {
    pending_trades_msg: "Found ",
    pending_trades_msg_2: "pending trades on ",
    show_completed: "show completed",
    switch_to_network: "Switch to",
    status: "Status",
    chatting_with_client: "Chatting with ramp client",
    message_history: "Message history",
    search_currencies: "Search Currencies",
    search_tokens: "Search Tokens",
    select_venue: "Select Venue",
    peer_id: "Peer id:",
    loading_trade_list: "Loading trade list",
    send: "Send",
    on: 'on',
    onramp_submitconf_user_message_1: ' now to Peer.',
    onramp_submitconf_user_message_2: 'Afterwards, enter the confirmation # from your ',
    onramp_submitconf_user_message_3: ' transaction below and submit.',
    onramp_init_user_message_1: 'Awaiting trade acceptance. Please check the chat for any unread messages from the provider.',
    onramp_init_user_message_2: "Once accepted, the provider's ",
    onramp_init_user_message_3: ' ID will appear below.',
    kyp_details: 'KYP Details:'
  },
  'pt': {
    pending_trades_msg: "Found ",
    pending_trades_msg_2: "pending trades on ",
    show_completed: "show completed",
    switch_to_network: "Switch to",
    status: "Status",
    chatting_with_client: "Chatting with ramp client",
    message_history: "Message history",
    search_currencies: "Search Currencies",
    search_tokens: "Search Tokens",
    select_venue: "Select Venue",
    peer_id: "Peer id:",
    loading_trade_list: "Loading trade list",
    send: "Send",
    on: 'on',
    onramp_submitconf_user_message_1: ' now to Peer.',
    onramp_submitconf_user_message_2: 'Afterwards, enter the confirmation # from your ',
    onramp_submitconf_user_message_3: ' transaction below and submit.',
    onramp_init_user_message_1: 'Awaiting trade acceptance. Please check the chat for any unread messages from the provider.',
    onramp_init_user_message_2: "Once accepted, the provider's ",
    onramp_init_user_message_3: ' ID will appear below.',
    kyp_details: 'KYP Details:'
  },
  'bn': {
    pending_trades_msg: "অপেক্ষমাণ ট্রেডেরগুলি ",
    pending_trades_msg_2: "অপেক্ষমাণ ট্রেডেরগুলির লিষ্ট ",
    show_completed: "সম্পন্ন ট্রেড দেখুন",
    switch_to_network: "নেটওয়ার্ক সুইচ করুন",
    status: "অবস্থা",
    chatting_with_client: "ক্লায়েন্টের সাথে চ্যাট করা হচ্ছে",
    message_history: "বার্তাসমূহের ইতিহাস",
    search_currencies: "মুদ্রা অনুসন্ধান করুন",
    search_tokens: "টোকেন অনুসন্ধান করুন",
    select_venue: "লেনদেন মাধ্যম নির্বাচন করুন",
    peer_id: "পিয়ার আইডি:",
    loading_trade_list: "ট্রেডের তালিকা লোড হচ্ছে",
    send: "পাঠান",
    on: 'অপেক্ষায়',
    onramp_submitconf_user_message_1: ' এখন ',
    onramp_submitconf_user_message_2: 'পরবর্তী পদক্ষেপে, তরলতা প্রদানকারীর কাছ থেকে কোনও বার্তা এসেছে কিনা তার জন্য চ্যাট চেক করুন।',
    onramp_submitconf_user_message_3: '',
    onramp_init_user_message_1: 'ট্রেড গ্রহণের অপেক্ষায়। দয়া করে সরবরাহকারীর কাছ থেকে কোনও অপ্রাপ্ত বার্তা আছে কিনা চেক করুন।',
    onramp_init_user_message_2: "গৃহীত হলে, সরবরাহকারীর ",
    onramp_init_user_message_3: ' আইডি নীচে প্রদর্শিত হবে।',
    kyp_details: 'KYP বিস্তারিত:'
  }
}

export const TradeStatuses: ITradeStatuses = {
  'en': {
    acceptancePending: 'Acceptance Pending',
    rejected: 'Rejected',
    awaitingPayment: 'Awaiting Payment',
    paymentConfirmationSent: 'Payment Conf. Sent',
    completed: 'Completed',
    cancelled: 'Cancelled',
    cancelReqMaker: 'Cancel Req: Maker',
    cancelReqTaker: 'Cancel Req: Taker',
    arbitrationMaker: 'Arbitration: Maker',
    arbitrationTaker: 'Arbitration: Taker',
    settledMaker: 'Settled: Maker',
    settledTaker: 'Settled: Taker',
    settledSplit: 'Settled: Split'
  },
  'es': {
    acceptancePending: 'Pendiente de Aceptación',
    rejected: 'Rechazado',
    awaitingPayment: 'En espera de Pago',
    paymentConfirmationSent: 'Confirmación de Pago Enviada',
    completed: 'Completado',
    cancelled: 'Cancelado',
    cancelReqMaker: 'Solicitud de Cancelación: Hacedor',
    cancelReqTaker: 'Solicitud de Cancelación: Tomador',
    arbitrationMaker: 'Arbitraje: Hacedor',
    arbitrationTaker: 'Arbitraje: Tomador',
    settledMaker: 'Resuelto: Hacedor',
    settledTaker: 'Resuelto: Tomador',
    settledSplit: 'Resuelto: Dividido'
  },
  'cz': {
    acceptancePending: 'Acceptance Pending',
    rejected: 'Rejected',
    awaitingPayment: 'Awaiting Payment',
    paymentConfirmationSent: 'Payment Conf. Sent',
    completed: 'Completed',
    cancelled: 'Cancelled',
    cancelReqMaker: 'Cancel Req: Maker',
    cancelReqTaker: 'Cancel Req: Taker',
    arbitrationMaker: 'Arbitration: Maker',
    arbitrationTaker: 'Arbitration: Taker',
    settledMaker: 'Settled: Maker',
    settledTaker: 'Settled: Taker',
    settledSplit: 'Settled: Split'
  },
  'pt': {
    acceptancePending: 'Acceptance Pending',
    rejected: 'Rejected',
    awaitingPayment: 'Awaiting Payment',
    paymentConfirmationSent: 'Payment Conf. Sent',
    completed: 'Completed',
    cancelled: 'Cancelled',
    cancelReqMaker: 'Cancel Req: Maker',
    cancelReqTaker: 'Cancel Req: Taker',
    arbitrationMaker: 'Arbitration: Maker',
    arbitrationTaker: 'Arbitration: Taker',
    settledMaker: 'Settled: Maker',
    settledTaker: 'Settled: Taker',
    settledSplit: 'Settled: Split'
  },
  'bn': {
    acceptancePending: 'গ্রহণের অপেক্ষায়',
    rejected: 'প্রত্যাখ্যাত',
    awaitingPayment: 'পেমেন্টের অপেক্ষায়',
    paymentConfirmationSent: 'পেমেন্ট কনফার্মেশন জন্য পাঠানো হয়েছে',
    completed: 'সম্পন্ন',
    cancelled: 'বাতিল',
    cancelReqMaker: 'বাতিলের অনুরোধ: নির্মাতা',
    cancelReqTaker: 'বাতিলের অনুরোধ: গ্রহণকারী',
    arbitrationMaker: 'বিচার: নির্মাতা',
    arbitrationTaker: 'বিচার: গ্রহণকারী',
    settledMaker: 'বসানো: নির্মাতা',
    settledTaker: 'বসানো: গ্রহণকারী',
    settledSplit: 'বসানো: বিভক্ত'
  }
}

export const AddLiqTexts: IAddLiqTexts = {
  'en': {
    onramp: 'Onramp',
    offramp: 'Offramp',
    select_token: 'Select Token',
    select_currency: 'Select Currency',
    spread: 'Spread',
    require_collateral: 'Require Collateral',
    min: "Min",
    max: "Max",
    taker: "Taker (optional)",
    onramp_tt: "You sell crypto to users",
    offramp_tt: "You buy crypto from users",
    spread_tt_1: "The percentage ",
    above: "above",
    below: "below",
    spread_tt_2: " the current market rate",
    for: "for",
    spread_tt_3: " that you will be charging on all trades initiated with this listing.",
    req_collateral_tt: "If you enable collateral, users will need to make a fully refundable RBX deposit in order to initiate a trade with your listing.",
    does_not_req_collateral_tt: "Does not require collateral",
    min_tt_1: "The minimum amount of ",
    crypto: "crypto",
    min_tt_2: "that will be allowed for trades with this listing.",
    max_tt_1: "The maximum amount of",
    max_tt_2: "that will be allowed for trades with this listing.",
    taker_tt: "If you fill out this field with a wallet address, only that wallet will be able to initiate trades with this listing.",
    kyp_tt: "Require up to 3 additional info fields that users must fill out when initiating a trade with this listing.",
    collat_warning_1: "You will need ",
    collat_warning_2: " in your wallet to submit this listing.",
    spread_limit_warning: "Trades will not be matched with this listing because it is over the spread limit of",
    has_id_fields_msg: 'has the following default ID input fields:',
    require_kyp: 'Require KYP',
    submit_liquidity: 'Submit Liquidity'
  },
  'es': {
    onramp: 'Onramp',
    offramp: 'Offramp',
    select_token: 'Elegir token',
    select_currency: 'Elegir Moneda/Divisa',
    spread: 'Spread',
    require_collateral: 'Requerir Colateral',
    min: "Cantidad Mín. para operar",
    max: "Cantidad Máx. para transacción",
    taker: "Tomador (Opcional)",
    onramp_tt: "Vender cripto a usuarios",
    offramp_tt: "Comprar cripto a usuarios",
    spread_tt_1: "El porcentaje ",
    above: "sobre",
    below: "bajo",
    spread_tt_2: " el precio de mercado",
    for: "para",
    spread_tt_3: " que cobrarás en todos los intercambios iniciados con este anuncio.",
    req_collateral_tt: "Si habilitas la garantía, los usuarios deberán hacer un depósito de RBX totalmente reembolsable para iniciar un intercambio con tu anuncio.",
    does_not_req_collateral_tt: "No requiere garantia",
    min_tt_1: "La cantidad mínima de",
    crypto: "cripto",
    min_tt_2: "que se permitirá para intercambios con este anuncio.",
    max_tt_1: "La cantidad maxima de",
    max_tt_2: "que se permitirá para intercambios con este anuncio.",
    taker_tt: "Si completas este campo con una dirección de billetera, solo esa billetera podrá iniciar intercambios con este anuncio.",
    kyp_tt: "Requiere hasta 3 campos de información adicionales que los usuarios deben completar al iniciar un intercambio con este anuncio.",
    collat_warning_1: "You will need ",
    collat_warning_2: " in your wallet to submit this listing.",
    spread_limit_warning: "Esta liquidez no sera emparejada con compradores al fijar el spread por encima de limite ",
    has_id_fields_msg: 'tiene los siguientes campos predefinidos:',
    require_kyp: 'Requiere KYP',
    submit_liquidity: 'Enviar Liquidez'
  },
  'cz': {
    onramp: 'Onramp',
    offramp: 'Offramp',
    select_token: 'Select Token',
    select_currency: 'Select Currency',
    spread: 'Spread',
    require_collateral: 'Require Collateral',
    min: "Min",
    max: "Max",
    taker: "Taker (optional)",
    onramp_tt: "You sell crypto to users",
    offramp_tt: "You buy crypto from users",
    spread_tt_1: "The percentage ",
    above: "above",
    below: "below",
    spread_tt_2: " the current market rate",
    for: "for",
    spread_tt_3: " that you will be charging on all trades initiated with this listing.",
    req_collateral_tt: "If you enable collateral, users will need to make a fully refundable RBX deposit in order to initiate a trade with your listing.",
    does_not_req_collateral_tt: "Does not require collateral",
    min_tt_1: "The minimum amount of ",
    crypto: "crypto",
    min_tt_2: "that will be allowed for trades with this listing.",
    max_tt_1: "The maximum amount of",
    max_tt_2: "that will be allowed for trades with this listing.",
    taker_tt: "If you fill out this field with a wallet address, only that wallet will be able to initiate trades with this listing.",
    kyp_tt: "Require up to 3 additional info fields that users must fill out when initiating a trade with this listing.",
    collat_warning_1: "You will need ",
    collat_warning_2: " in your wallet to submit this listing.",
    spread_limit_warning: "Trades will not be matched with this listing because it is over the spread limit of",
    has_id_fields_msg: 'has the following default ID input fields:',
    require_kyp: 'Require KYP',
    submit_liquidity: 'Submit Liquidity'
  },
  'pt': {
    onramp: 'Onramp',
    offramp: 'Offramp',
    select_token: 'Select Token',
    select_currency: 'Select Currency',
    spread: 'Spread',
    require_collateral: 'Require Collateral',
    min: "Min",
    max: "Max",
    taker: "Taker (optional)",
    onramp_tt: "You sell crypto to users",
    offramp_tt: "You buy crypto from users",
    spread_tt_1: "The percentage ",
    above: "above",
    below: "below",
    spread_tt_2: " the current market rate",
    for: "for",
    spread_tt_3: " that you will be charging on all trades initiated with this listing.",
    req_collateral_tt: "If you enable collateral, users will need to make a fully refundable RBX deposit in order to initiate a trade with your listing.",
    does_not_req_collateral_tt: "Does not require collateral",
    min_tt_1: "The minimum amount of ",
    crypto: "crypto",
    min_tt_2: "that will be allowed for trades with this listing.",
    max_tt_1: "The maximum amount of",
    max_tt_2: "that will be allowed for trades with this listing.",
    taker_tt: "If you fill out this field with a wallet address, only that wallet will be able to initiate trades with this listing.",
    kyp_tt: "Require up to 3 additional info fields that users must fill out when initiating a trade with this listing.",
    collat_warning_1: "You will need ",
    collat_warning_2: " in your wallet to submit this listing.",
    spread_limit_warning: "Trades will not be matched with this listing because it is over the spread limit of",
    has_id_fields_msg: 'has the following default ID input fields:',
    require_kyp: 'Require KYP',
    submit_liquidity: 'Submit Liquidity'
  },
  'bn': {
    onramp: 'অনর‍্যাম্প',
    offramp: 'অফর‍্যাম্প',
    select_token: 'টোকেন নির্বাচন করুন',
    select_currency: 'মুদ্রা নির্বাচন করুন',
    spread: 'স্প্রেড',
    require_collateral: 'জামানত প্রয়োজন',
    min: "ন্যূনতম",
    max: "সর্বাধিক",
    taker: "গ্রহণকারী (ঐচ্ছিক)",
    onramp_tt: "আপনি ব্যবহারকারীদের কাছে ক্রিপ্টো বিক্রি করেন",
    offramp_tt: "আপনি ব্যবহারকারীদের কাছ থেকে ক্রিপ্টো কিনেন",
    spread_tt_1: "ভগ্নাংশ ",
    above: "উপর",
    below: "নিচে",
    spread_tt_2: " বর্তমান বাজার মূল্যের",
    for: "এর জন্য",
    spread_tt_3: "আপনার তালিকার সাথে শুরু হওয়া সমস্ত ট্রেডে আপনি যে চার্জ করবেন।",
    req_collateral_tt: "যদি আপনি জামানত দিতে সক্ষম হলে, ব্যবহারকারীদের আপনার তালিকার সাথে একটি ট্রেড শুরু করতে পূর্ণাঙ্গভাবে ফেরতযোগ্য RBX জামানত করতে হবে।",
    does_not_req_collateral_tt: "জামানতের প্রয়োজন নেই",
    min_tt_1: "এই তালিকার সাথে ট্রেডের জন্য অনুমোদিত ",
    crypto: "ক্রিপ্টো",
    min_tt_2: "ন্যূনতম পরিমাণ।",
    max_tt_1: "এই তালিকার সাথে ট্রেডের জন্য অনুমোদিত সর্বাধিক পরিমাণ",
    max_tt_2: "।",
    taker_tt: "যদি আপনি এই ফিল্ডে একটি ওয়ালেটর ঠিকানা পূরণ করেন, তবে শুধুমাত্র সেই ওয়ালেট এই তালিকার সাথে ট্রেড শুরু করতে পারবেন।",
    kyp_tt: "তালিকার সাথে ট্রেড শুরু করার সময় ব্যবহারকারীদের পূরণ করতে হবে এমন ৩টি অতিরিক্ত তথ্য ক্ষেত্র প্রয়োজন।",
    collat_warning_1: "এই তালিকা জমা দেওয়ার জন্য আপনার ওয়ালেটের প্রয়োজন ",
    collat_warning_2: "।",
    spread_limit_warning: "এই তালিকার সাথে ট্রেডগুলি মেলানো হবে না কারণ এটি স্প্রেড সীমার উপরে রয়েছে",
    has_id_fields_msg: 'নিচের ডিফল্ট আইডি ইনপুট ক্ষেত্রগুলি রয়েছে:',
    require_kyp: 'KYP প্রয়োজন',
    submit_liquidity: 'তারল্য জমা দিন'
  }
}

export const SkipTexts: ISkipTexts = {
  'en': {
    buy_crypto: 'Buy Crypto',
    sell_crypto: 'Sell Crypto',
    my_trades: 'My Trades',
    my_listings: 'My Listings',
    add_liquidity: 'Add Liquidity',
    settings: 'Settings',
    selected_network: 'Selected Network',
    you_pay: 'You Pay',
    you_receive: 'You Receive',
    payment_venue_id: 'Payment Venue ID',
    enter_venue_id: 'Enter Venue ID',
    rate: 'Rate',
    select_venue: 'Select Venue',
    search_venue: 'Search Venue',
    select_token: 'Select Coin',
    select_fiat: 'Select Fiat',
    token_list: 'Token List',
    currency_list: 'Currency List',
    available_venues: 'Available Payment Venues',
    no_trades_yet: 'No Pending Trades',
    no_listings_found: 'No Listings Found',
    connect_wallet: 'Connect Wallet',
    requires_collateral: 'Requires Collateral',
    autocollat_warning: 'This trade will be automatically collateralized using ',
    autocollat_warning_2: ', or equivalent',
    autocollat_warning_3: ' from your wallet.',
    no_collat_required: 'This trade will not require collateralization.',
    no_collat_required_id_inputs: 'No collateral required',
    score: 'Score',
    information: "Information",
    pending_trade_warning: 'You already have a SkipShift trade pending on ',
    pending_trade_warning_2: '. Click on the "My trades" tab to view the status.',
    select_both_message: `Select both the fiat currency you'd like to use and the cryptocurrency you'd like to purchase. Then, select a payment venue to begin!`,
    post_submit_message_on: `After submitting your trade, it will appear in the 'My Trades' section. Once accepted by the provider, simply send the funds to the provider's username on your chosen venue.`,
    post_submit_message_off: 'After submitting your trade, it will appear in the "My Trades" section. Once accepted by the provider, confirm their payment receipt on chain to complete the trade.',
    show_inactive_trades: "Show inactive trades",
    provider_mode: "Provider mode",
    verify_wallet: "Verify Wallet",
    select_assets: "Select Assets",
    submit_trade: 'Submit trade',
    decollateralize: 'Decollateralize',
    submitting_trade: 'Submitting trade...',
    try_another_chain: 'Try another chain',
    enter_kyp_details: 'Enter KYP Details',
    minimum_not_met: 'Minimum not met',
    not_enough_liquidity: 'Not enough liquidity',
    minmax_notif_1: 'Please adjust your trade size to be between',
    and: 'and',
    on: 'on',
    trades_between: 'Trades between',
    minmax_notif_2: 'are available via',
    view_more_rates: 'View more rates'
  },
  'es': {
    buy_crypto: 'Comprar cripto',
    sell_crypto: 'Vender cripto',
    my_trades: 'Mis trades',
    my_listings: 'Mis Listas',
    add_liquidity: 'Agrega Liq.',
    settings: 'Configuración',
    selected_network: 'Red elegida',
    you_pay: 'Usted paga',
    you_receive: 'Usted recibe',
    payment_venue_id: 'ID del método de pago',
    enter_venue_id: 'Introduce ID de pago',
    rate: 'Tasa',
    select_venue: 'Elegir lugar',
    search_venue: 'Buscar método',
    select_token: 'Elegir token',
    select_fiat: 'Elegir Moneda',
    token_list: 'Lista de Tokens',
    currency_list: 'Lista de divisas',
    available_venues: 'Métodos de pago aceptados',
    no_trades_yet: 'Sin operaciones',
    no_listings_found: 'No se encontraron posiciones',
    connect_wallet: 'Conectar Cartera',
    requires_collateral: 'Requiere coleteral',
    autocollat_warning: 'Esta operación se colateralizará automáticamente usando ',
    autocollat_warning_2: ', o',
    autocollat_warning_3: ' equivalente, de tu billetera.',
    no_collat_required: 'Este intercambio no requerirá colateral.',
    score: 'Puntuación',
    information: 'Información',
    pending_trade_warning: 'Ya tienes un intercambio de SkipShift pendiente en ',
    pending_trade_warning_2: '. Haz clic en la pestaña "Mis intercambios" para ver el estado.',
    select_both_message: 'Selecciona tanto la divisa que deseas utilizar como la criptomoneda que deseas comprar. Luego, elige un metodo de pago para comenzar.',
    post_submit_message_on: `Después de enviar tu trade, aparecerá en la sección 'Mis trades'. Una vez aceptado por el proveedor, simplemente envía los fondos al nombre de usuario del proveedor en el metodo de pago elegido.`,
    post_submit_message_off: `Después de enviar tu trade, aparecerá en la sección 'Mis trades'. Una vez aceptado por el proveedor, confirma su recibo de pago en la cadena para completar la operación.`,
    show_inactive_trades: "Mostrar intercambios inactivos",
    provider_mode: "Modo proveedor",
    verify_wallet: "Verificar billetera",
    select_assets: "Seleccionar activos",
    submit_trade: 'Procesar operación',
    decollateralize: 'Decollateralize',
    submitting_trade: 'Enviando intercambio...',
    try_another_chain: 'Prueba con otra cadena',
    enter_kyp_details: 'Ingresa los detalles de KYP',
    minimum_not_met: 'Mínimo no alcanzado',
    not_enough_liquidity: 'Not enough liquidity',
    minmax_notif_1: 'Por favor, ajuste el tamaño de su operación para que esté entre',
    and: 'y',
    on: 'en',
    trades_between: 'Las operaciones entre',
    minmax_notif_2: 'están disponibles a través de',
    no_collat_required_id_inputs: 'No se requiere garantía',
    view_more_rates: 'Ver más tarifas'
  },
  'cz': {
    buy_crypto: 'Koupit krypto',
    sell_crypto: 'Prodat krypto',
    my_trades: 'Moje obchody',
    my_listings: 'Moje Pozice',
    add_liquidity: 'Add Liquidity',
    settings: 'Nastavení',
    selected_network: 'Vybraná síť',
    you_pay: 'Zaplatíte',
    you_receive: 'Přijmete',
    payment_venue_id: 'ID platebního místa',
    enter_venue_id: 'Zadejte ID místa',
    rate: 'Kurz',
    select_venue: 'Vybrat místo',
    search_venue: 'Search Venue',
    select_token: 'Vybrat token',
    select_fiat: 'Select Fiat',
    token_list: 'Seznam tokenů',
    currency_list: 'Seznam měn',
    available_venues: 'Dostupná místa',
    no_trades_yet: 'Zatím žádné obchody',
    no_listings_found: 'Nenalezeny žádné pozice',
    connect_wallet: 'Připojit peněženku',
    requires_collateral: 'Requires Collateral',
    autocollat_warning: 'This trade will be automatically collateralized using ',
    autocollat_warning_2: ', or',
    autocollat_warning_3: ' equivalent, from your wallet.',
    no_collat_required: 'This trade will not require collateralization.',
    score: 'Score',
    information: "Information",
    pending_trade_warning: 'You already have a SkipShift trade pending on ',
    pending_trade_warning_2: '. Click on the "My trades" tab to view the status.',
    select_both_message: `Select both the fiat currency you'd like to use and the cryptocurrency you'd like to purchase. Then, select a payment venue to begin!`,
    post_submit_message_on: `After submitting your trade, it will appear in the 'My Trades' section. Once accepted by the provider, simply send the funds to the provider's username on your chosen venue.`,
    post_submit_message_off: 'After submitting your trade, it will appear in the "My Trades" section. Once accepted by the provider, confirm their payment receipt on chain to complete the trade.',
    show_inactive_trades: "Show inactive trades",
    provider_mode: "Provider mode",
    verify_wallet: "Verify Wallet",
    select_assets: "Select Assets",
    submit_trade: 'Submit trade',
    decollateralize: 'Decollateralize',
    submitting_trade: 'Submitting trade...',
    try_another_chain: 'Try another chain',
    enter_kyp_details: 'Enter KYP Details',
    minimum_not_met: 'Minimum not met',
    not_enough_liquidity: 'Not enough liquidity',
    minmax_notif_1: 'Please adjust your trade size to be between',
    and: 'and',
    on: 'on',
    trades_between: 'Trades between',
    minmax_notif_2: 'are available via',
    no_collat_required_id_inputs: 'No collateral required',
    view_more_rates: 'View more rates'
  },
  
  'pt': {  
    buy_crypto: 'Comprar cripto',
    sell_crypto: 'Vender cripto',
    my_trades: 'Minhas negociações',
    my_listings: 'My Listings',
    add_liquidity: 'Add Liquidity',
    settings: 'Configurações',
    selected_network: 'Rede selecionada',
    you_pay: 'Você paga',
    you_receive: 'Você recebe',
    payment_venue_id: 'ID do local de pagamento',
    enter_venue_id: 'Insira o ID do local',
    rate: 'Taxa',
    select_venue: 'Selecionar local',
    search_venue: 'Search Venue',
    select_token: 'Selecionar Token',
    select_fiat: 'Select Fiat',
    token_list: 'Lista de tokens',
    currency_list: 'Lista de moedas',
    available_venues: 'Locais disponíveis',
    no_trades_yet: 'Nenhuma negociação ainda',
    no_listings_found: 'No Listings Found',
    connect_wallet: 'Conectar Carteira',
    requires_collateral: 'Requires Collateral',
    autocollat_warning: 'This trade will be automatically collateralized using ',
    autocollat_warning_2: ' or',
    autocollat_warning_3: ' equivalent, from your wallet.',
    no_collat_required: 'This trade will not require collateralization.',
    score: 'Score',
    information: "Information",
    pending_trade_warning: 'You already have a SkipShift trade pending on ',
    pending_trade_warning_2: '. Click on the "My trades" tab to view the status.',
    select_both_message: `Select both the fiat currency you'd like to use and the cryptocurrency you'd like to purchase. Then, select a payment venue to begin!`,
    post_submit_message_on: `After submitting your trade, it will appear in the 'My Trades' section. Once accepted by the provider, simply send the funds to the provider's username on your chosen venue.`,
    post_submit_message_off: 'After submitting your trade, it will appear in the "My Trades" section. Once accepted by the provider, confirm their payment receipt on chain to complete the trade.',
    show_inactive_trades: "Show inactive trades",
    provider_mode: "Provider mode",
    verify_wallet: "Verify Wallet",
    select_assets: "Select Assets",
    submit_trade: 'Submit trade',
    decollateralize: 'Decollateralize',
    submitting_trade: 'Submitting trade...',
    try_another_chain: 'Try another chain',
    enter_kyp_details: 'Enter KYP Details',
    minimum_not_met: 'Minimum not met',
    not_enough_liquidity: 'Not enough liquidity',
    minmax_notif_1: 'Please adjust your trade size to be between',
    and: 'and',
    on: 'on',
    trades_between: 'Trades between',
    minmax_notif_2: 'are available via',
    no_collat_required_id_inputs: 'No collateral required',
    view_more_rates: 'View more rates'
  },
  'bn': {
    buy_crypto: 'ক্রিপ্টো কিনুন',
    sell_crypto: 'ক্রিপ্টো বিক্রি করুন',
    my_trades: 'আমার ট্রেড',
    my_listings: 'আমার লিস্টিং',
    add_liquidity: 'তরলতা যোগ করুন',
    settings: 'সেটিংস',
    selected_network: 'নির্বাচিত নেটওয়ার্ক',
    you_pay: 'আপনি প্রদান করবেন',
    you_receive: 'আপনি পাবেন',
    payment_venue_id: 'পেমেন্ট ভেন্যু আইডি',
    enter_venue_id: 'ভেন্যু আইডি প্রবেশ করুন',
    rate: 'হার',
    select_venue: 'লেনদেন মাধ্যম নির্বাচন করুন',
    search_venue: 'Search Venue',
    select_token: 'টোকেন নির্বাচন করুন',
    select_fiat: 'ফিয়াট নির্বাচন করুন',
    token_list: 'টোকেনের তালিকা',
    currency_list: 'মুদ্রার তালিকা',
    available_venues: 'উপলব্ধ পেমেন্ট মাধ্যম',
    no_trades_yet: 'কোনো_PENDING ট্রেড নেই',
    no_listings_found: 'কোনো লিস্টিং পাওয়া যায়নি',
    connect_wallet: 'ওয়ালেট সংযুক্ত করুন',
    requires_collateral: 'জামানত প্রয়োজন',
    autocollat_warning: 'এই ট্রেডটি স্বয়ংক্রিয়ভাবে আপনার ওয়ালেট থেকে ',
    autocollat_warning_2: ', অথবা',
    autocollat_warning_3: ' সমমূল্যের ব্যবহার করে বন্ধকীকৃত হবে।',
    no_collat_required: 'এই ট্রেডের জন্য জামানত প্রয়োজন হবে না।',
    no_collat_required_id_inputs: 'জামানত প্রয়োজন নেই',
    score: 'স্কোর',
    information: "তথ্য",
    pending_trade_warning: 'আপনার একটি SkipShift ট্রেড ইতিমধ্যে অপেক্ষমাণ আছে ',
    pending_trade_warning_2: '. অবস্থা দেখতে "আমার ট্রেড" ট্যাবে ক্লিক করুন।',
    select_both_message: 'যে ফিয়াট মুদ্রা আপনি ব্যবহার করতে চান এবং ক্রিপ্টোকারেন্সি আপনি কিনতে চান তা নির্বাচন করুন। তারপর, একটি পেমেন্ট মাধ্যম নির্বাচন করুন ট্রেড শুরু করার জন্য!',
    post_submit_message_on: 'আপনার ট্রেড জমা দেওয়ার পরে, এটি "আমার ট্রেড" বিভাগে প্রদর্শিত হবে। প্রদানকারী দ্বারা গৃহীত হলে, সরবরাহকারীর ব্যবহারকারীর নামের কাছে তহবিল পাঠান আপনার নির্বাচিত স্থানে।',
    post_submit_message_off: 'আপনার ট্রেড জমা দেওয়ার পরে, এটি "আমার ট্রেড" বিভাগে প্রদর্শিত হবে। প্রদানকারী দ্বারা গৃহীত হলে, ট্রেড সম্পন্ন করতে চেইনে তাদের পেমেন্ট গ্রহণ নিশ্চিত করুন।',
    show_inactive_trades: "অকার্যকর ট্রেড দেখান",
    provider_mode: "প্রদানকারী মোড",
    verify_wallet: "ওয়ালেট যাচাই করুন",
    select_assets: "সম্পদ নির্বাচন করুন",
    submit_trade: 'ট্রেড জমা দিন',
    decollateralize: 'জামানত মুক্ত করুন',
    submitting_trade: 'ট্রেড জমা দেওয়া হচ্ছে...',
    try_another_chain: 'আরেকটি চেইনে চেষ্টা করুন',
    enter_kyp_details: 'KYP বিস্তারিত প্রবেশ করুন',
    minimum_not_met: 'ন্যূনতম পূরণ হয়নি',
    not_enough_liquidity: 'Not enough liquidity',
    minmax_notif_1: 'অনুগ্রহ করে আপনার লেনদেনের আকার সমন্বয় করুন, যা হতে হবে',
    and: 'এবং',
    on: 'এ',
    trades_between: 'এর মধ্যে লেনদেন',
    minmax_notif_2: 'এর মাধ্যমে পাওয়া যায়',
    view_more_rates: 'আরও হার দেখুন'
  }
}

export const MenuTexts: IMenuTexts = {
  'en': {
    resources: `RESOURCES`,
    features: `Features`,
    alerts: `Alerts`,
    integrate: `Integrate`,
    documentation: `Documentation`,
    products: `Products`,
    partners: `Partners`,
    venues: `Venues`,
    about: `About`,
    subscribe: `Subscribe`,
    skip_limits: `Skip limits. Shift control.`,
    more_info: `MORE INFO`,
    all_rights_reserved: 'All rights reserved.'
  },
  'es': {
    resources: `RECURSOS`,
    features: `Características`,
    alerts: `Alertas`,
    integrate: `Integrar`,
    documentation: `Documentación`,
    products: `Productos`,
    partners: `Socios`,
    venues: `Métodos de Pago`,
    about: `Acerca de`,
    subscribe: `Suscribir`,
    skip_limits: `SkipShift Salta los límites. Controla el cambio.`,
    more_info: `MÁS INFORMACIÓN`,
    all_rights_reserved: 'Todos los derechos reservados.'
  },
  'cz': {
    resources: `RESOURCES`,
    features: `Features`,
    alerts: `Alerts`,
    integrate: `Integrate`,
    documentation: `Documentation`,
    products: `Products`,
    partners: `Partners`,
    venues: `Venues`,
    about: `About`,
    subscribe: `Subscribe`,
    skip_limits: `Skip limits. Shift control.`,
    more_info: `MORE INFO`,
    all_rights_reserved: 'All rights reserved.'
  },
  'pt': {
    resources: `RECURSOS`,
    features: `Características`,
    alerts: `Alertas`,
    integrate: `Integrar`,
    documentation: `Documentação`,
    products: `Produtos`,
    partners: `Parceiros`,
    venues: `Locais`,
    about: `Sobre`,
    subscribe: `Assinar`,
    skip_limits: `Mais info. Controle de mudança.`,
    more_info: `Mais info`,
    all_rights_reserved: 'Todos os direitos reservados.'
  },
  'bn': {
    resources: 'সম্পদ',
    features: 'ফিচার',
    alerts: 'অ্যালার্ট',
    integrate: 'ইন্টিগ্রেট',
    documentation: 'ডকুমেন্টেশন',
    products: 'প্রোডাক্টস',
    partners: 'পার্টনার্স',
    venues: 'ভেন্যু',
    about: 'আমাদের সম্পর্কে',
    subscribe: 'সাবস্ক্রাইব',
    skip_limits: 'সীমা এড়ান। নিয়ন্ত্রণ স্থানান্তর করুন।',
    more_info: 'আরও তথ্য',
    all_rights_reserved: 'সর্বস্বত্ব সংরক্ষিত।'
  }
}

export const PageTexts: IPageTexts = {
  'en': {
    skip_the_questions: 'Skip the questions.',
    shift_control: 'Shift control.',
    homepage_top_desc: 'As often, as much, and from anywhere: SkipShift allows anyone to swap fiat to crypto and back using 100% decentralized infrastructure.',
    homepage_bottom_desc: 'That means no more KYC, no more selfies, and no more limits.',
    what_weve_built: "What we've built",
    products_desc: "SkipShift seamlessly bridges fiat and crypto, offering users effortless conversions and providers a chance at passive income. Crafted with proprietary code and rigorously tested, it's a cutting-edge platform built by traders, for traders.",
    global_payment_methods: 'Global Payment Methods',
    liquidity_provision: 'Liquidity Provision',
    risk_mitigation: 'Risk Mitigation',
    toolkit: 'Toolkit',
    global_payment_methods_desc: "At SkipShift, we understand the diverse needs of our global user base. That's why we proudly support a vast array of payment methods, ensuring that every user finds a method that suits them. From popular platforms like Paypal, Cashapp, and Chime to international favorites like Revolut, Payoneer, Money Transfer, and Western Union, our commitment is to provide unparalleled accessibility. No matter where you are, SkipShift ensures you're always connected.",
    liquidity_provision_desc: "Liquidity is the lifeblood of any financial platform, and at SkipShift, it's a community-driven endeavor. Our unique approach allows individuals to step in as liquidity providers, signaling their readiness to trade specific assets. This decentralized method not only democratizes the trading process but also ensures that users can always find a match for their trading needs. With SkipShift, every transaction is more than just a trade; it's a connection.",
    risk_mitigation_desc: "Safety and trust are paramount at SkipShift. While we champion decentralization, we also prioritize the security of our users. Our platform integrates advanced risk mitigation techniques, from escrow smart contracts that ensure transaction integrity to collateralization that provides an added layer of security. And in the rare event of a dispute? Our arbitration process ensures fair resolution. With SkipShift, you're not just trading; you're trading with confidence.",
    toolkit_desc: "Whether you're looking to convert assets or provide liquidity, SkipShift offers a comprehensive toolkit tailored to your needs. Users can effortlessly onboard or offboard any asset, while providers have a suite of tools at their disposal to monetize their holdings. And for businesses or platforms looking to integrate our services, the SkipShift widget ensures that top-tier financial services are just a click away.",
    partners_title: "SkipShift powers the best in web3",
    partners_desc: "Collaboration is at the heart of innovation. At SkipShift, we're proud to partner with industry leaders across various sectors, Dive in and discover the synergies that make SkipShift a market leader.",
    and_more: "...and more!",
    methods_title: 'Use any payment method',
    methods_desc: "Visual clarity is key. Our platform showcases the logos of all supported fiat payment methods and blockchains, offering users a clear snapshot of the myriad options at their fingertips. With SkipShift, choices are not just abundant; they're evident.",
    available_on: "Available on 3+ networks, with more on the way.",
    integrate: "Integrate",
    faq_left_title: "SkipShift: Where Fiat Meets Crypto",
    faq_left_desc: "SkipShift seamlessly bridges fiat and crypto using decentralized infrastructure. No KYC, no selfies, just effortless conversions with a plethora of global payment methods. Crafted by traders, for traders, it's the future of currency exchange.",
    faq_left_sub_desc: "Dive deep, discover more, and join the SkipShift revolution.",
    faq_right_title: "Frequently Asked Questions",
    subscribe_title: 'Subscribe to our mailing list to stay up to date.',
    subscribe: 'Subscribe'
  },
  'es': {
    skip_the_questions: 'Salta las preguntas.',
    shift_control: 'Controla el cambio.',
    homepage_top_desc: 'Cuantas veces quieras y desde cualquier lugar. SkipShift permite a cualquiera cambiar de Fiat a cripto y viceversa utilizando una infraestructura 100% descentralizada.',
    homepage_bottom_desc: 'Eso significa que no hace falta verificación de ID, no hace falta más selfies y sin más restricciones.',
    what_weve_built: "Lo que hemos construido",
    products_desc: "SkipShift conecta de manera eficiente divisas Fiat y las criptomonedas, ofreciendo a los usuarios conversiones sin esfuerzo y a los proveedores la oportunidad de obtener ingresos pasivos. Creado con código propio y probado rigurosamente, es una plataforma de vanguardia construida por traders, para traders.",
    global_payment_methods: 'Métodos de Pago Globales ',
    liquidity_provision: 'Provisión de Liquidez',
    risk_mitigation: 'Mitigación de Riesgos',
    toolkit: 'Herramientas',
    global_payment_methods_desc: "En SkipShift, entendemos las diversas necesidades de nuestra base de usuarios en todo el mundo. Es por eso que apoyamos con orgullo una amplia variedad de métodos de pago, asegurando que cada usuario encuentre un método que le convenga. Desde plataformas populares como Paypal, Cashapp y Chime hasta favoritos internacionales como Revolut, Payoneer, Money Transfer y Western Union, nuestro compromiso es proporcionar una accesibilidad incomparable. No importa dónde te encuentres, SkipShift asegura que siempre estés conectado.",
    liquidity_provision_desc: "Disponibilidad de liquidez es vital en cualquier plataforma financiera, y en SkipShift, es un esfuerzo impulsado por la comunidad. Nuestro enfoque único permite que las personas se conviertan en proveedores de liquidez, señalando su disposición para intercambiar activos específicos. Este método descentralizado no solo democratiza el proceso de negociación, sino que también garantiza que los usuarios siempre puedan encontrar una contraparte para sus necesidades comerciales. Con SkipShift, cada transacción es más que un simple intercambio; es una conexión.",
    risk_mitigation_desc: "La seguridad y la confianza son primordiales en SkipShift. Si bien defendemos la descentralización, también damos prioridad a la seguridad de nuestros usuarios. Nuestra plataforma integra técnicas avanzadas de mitigación de riesgos, desde contratos inteligentes de garantía que aseguran la integridad de las transacciones hasta la colateralización que proporciona una capa adicional de seguridad. ¿Y en el raro caso de una disputa? Nuestro proceso de arbitraje garantiza una resolución justa. Con SkipShift, no solo estás comerciando, estás comerciando con confianza.",
    toolkit_desc: "Ya sea convertir activos o proporcionar liquidez, SkipShift ofrece un conjunto de herramientas completo adaptado a tus necesidades. Los usuarios pueden incorporar o retirar activos sin esfuerzo, mientras que los proveedores tienen una serie de herramientas a su disposición para rentabilizar sus activos. Para empresas o plataformas que buscan integrar nuestros servicios, el widget de SkipShift garantiza que los servicios financieros de primer nivel estén a solo un clic de distancia.",
    partners_title: "SkipShift impulsa lo mejor en web3",
    partners_desc: "La colaboración es el corazón de la innovación. En SkipShift, nos enorgullece asociarnos con líderes de la industria en diversos sectores. Sumérgete y descubre las sinergias que hacen de SkipShift un líder en el mercado.",
    and_more: "...y más!",
    methods_title: 'Utiliza cualquier método de pago',
    methods_desc: "La claridad visual es clave. Nuestra plataforma muestra los logotipos de todos los métodos de pago compatibles y las cadenas de bloques aceptadas, ofreciendo a los usuarios una imagen clara de las numerosas opciones a su alcance. Con SkipShift, las opciones no solo son abundantes; son evidentes.",
    available_on: "Disponible en más de 3 redes, con más en camino.",
    integrate: "Integrar",
    faq_left_title: "SkipShift: Donde Fiat se encuentra con Cripto.",
    faq_left_desc: "SkipShift conecta de manera fluida mas divisas Fiat y las criptomonedas utilizando una infraestructura descentralizada. Sin KYC, sin selfies, solo conversiones sin esfuerzo con una gran cantidad de métodos de pago globales. Creado por comerciantes, para comerciantes, es el futuro del intercambio de divisas.",
    faq_left_sub_desc: "Adéntrate y descubremos! Únete a la revolución de SkipShift.",
    faq_right_title: "Preguntas Frecuentes",
    subscribe_title: 'Suscríbete a nuestra lista de correo para mantenerte actualizado.',
    subscribe: 'Suscríbete'
  },
  'cz': {
    skip_the_questions: 'Přeskočte otázky.',
    shift_control: 'Převezměte kontrolu.',
    homepage_top_desc: 'Kdykoliv a odkudkoliv. SkipShift umožňuje komukoli měnit fiat na krypto a naopak pomocí 100% decentralizované infrastruktury.',
    homepage_bottom_desc: 'To znamená žádné ověřování identity, žádná selfies a jiná omezení.',
    what_weve_built: "Co jsme vytvořili",
    products_desc: "SkipShift efektivně propojuje fiat a krypto, nabízí uživatelům bezproblémové konverze a možnost pasivního příjmu. Je to špičková platforma vytvořená pomocí vlastního kódu a přísně testovaná, stvořená tradery pro tradery.",
    global_payment_methods: 'Globální platební metody',
    liquidity_provision: 'Poskytování likvidity',
    risk_mitigation: 'Snižování rizik',
    toolkit: 'Nástroje',
    global_payment_methods_desc: "Ve společnosti SkipShift rozumíme různorodým potřebám našich uživatelů z celého světa. Proto podporujeme širokou škálu platebních metod, abychom zajistili, že každý uživatel najde tu, která mu vyhovuje, od oblíbených platforem jako Paypal, Cashapp a Chime až po mezinárodní platformy, jako je Revolut, Payoneer, Money Transfer a Western Union. Je naším závazkem poskytovat bezkonkurenční dostupnost bez ohledu na to, kde zrovna jste.",
    liquidity_provision_desc: "Likvidita je životní mízou jakékoliv finanční platformy a ve společnosti SkipShift je to úsilí řízené samotnou komunitou. Náš jedinečný přístup umožňuje jednotlivcům vstoupit jako poskytovatelé likvidity a signalizovat jejich připravenost obchodovat s konkrétními aktivy. Tato decentralizovaná metoda nejen demokratizuje obchodní proces, ale také zajišťuje, že uživatelé mohou vždy najít odpovídající nabídku pro své obchodní potřeby. Se SkipShift je každá transakce více než jen obchod; je to spojení.",
    risk_mitigation_desc: "Bezpečnost a důvěra jsou u společnosti SkipShift na prvním místě. Jsme zastánci decentralizace, ale zároveň klademe důraz na bezpečí našich uživatelů. Naše platforma podporuje pokročilé techniky snižování rizik, od svěřeneckých smart kontraktů, které zajišťují poctivost transakcí, až po kolateralizaci, která zaručuje další úroveň zabezpečení. A ve vzácném případě sporu? Náš arbitrážní proces zaručuje spravedlivé řešení. Se SkipShiftem nejen obchodujete, ale obchodujete s důvěrou.",
    toolkit_desc: "Ať už chcete směnit aktiva nebo poskytnout likviditu, SkipShift nabízí komplexní sadu nástrojů přizpůsobenou vašim potřebám. Uživatelé mohou snadno vkládat nebo vybírat jakékoli aktivum, zatímco poskytovatelé likvidity mají k dispozici sadu nástrojů pro zhodnocení svých aktiv. A podnikům nebo platformám, které chtějí integrovat naše služby na svůj web, widget SkipShift nabízí špičkové finanční služby dostupné pouhým kliknutím.",
    partners_title: "SkipShift rozvíjí to nejlepší z Webu3",
    partners_desc: "Spolupráce je základem inovací. Ve společnosti SkipShift jsme hrdí na to, že můžeme spolupracovat s lídry v různých odvětvích. Objevte synergie, díky kterým je SkipShift jedním z největších inovátorů na trhu.",
    and_more: "...a více!",
    methods_title: 'Použijte jakýkoliv způsob platby',
    methods_desc: "Vizuální přehlednost je klíčová. Naše platforma zobrazuje loga všech podporovaných fiat platebních metod a blockchainů a nabízí uživatelům přehledný obrázek o nesčetných možnostech, které mohou využít. Se SkipShiftem je výběr nejen široký, ale i evidentní",
    available_on: "K dispozici na více než 3 sítích a další jsou na cestě.",
    integrate: "Připojit",
    faq_left_title: "SkipShift: Kde se Fiat setkává s kryptem.",
    faq_left_desc: "SkipShift je revoluční produkt představený společností RBX. Jedná se o plně decentralizovanou peer-to-peer platformu, která zprostředkovává bezproblémový nákup a prodej a přechod mezi fiatem a kryptem, a to bez nutnosti ověřování identity. Celý proces je automatizovaný, bezpečný a probíhá v rámci blockchainu.",
    faq_left_sub_desc: "Zjistěte více a připojte se k revoluci SkipShift.",
    faq_right_title: "Časté dotazy",
    subscribe_title: 'Subscribe to our mailing list to stay up to date.',
    subscribe: 'Subscribe'
  },
  'pt': {
    skip_the_questions: 'Pule as perguntas.',
    shift_control: 'Controle a mudança!',
    homepage_top_desc: 'Quantas vezes quiser e de qualquer lugar. SkipShift permite que qualquer pessoa mude de Fiat para criptomoeda e vice-versa usando uma infraestrutura 100% descentralizada.',
    homepage_bottom_desc: 'Isso significa que não há mais verificação de identidade, nem mais selfies, nem mais restrições.',
    what_weve_built: "O que construímos",
    products_desc: "SkipShift conecta com eficiência moedas Fiat e criptomoedas, oferecendo aos usuários conversões sem esforço e aos provedores a oportunidade de obter renda passiva. Construída com código proprietário e rigorosamente testada, é uma plataforma de ponta construída por traders, para traders.",
    global_payment_methods: 'Métodos de Pagamento Globais',
    liquidity_provision: 'Fornecimento de Liquidez',
    risk_mitigation: 'Mitigação de Riscos',
    toolkit: 'Ferramentas',
    global_payment_methods_desc: "Na SkipShift, entendemos as diversas necessidades da nossa base de usuários em todo o mundo. É por isso que apoiamos orgulhosamente uma ampla variedade de métodos de pagamento, garantindo que cada usuário encontre o método que mais lhe convém. Desde plataformas populares como Paypal, Cashapp e Chime até favoritas internacionais como Revolut, Payoneer, Money Transfer e Western Union, estamos empenhados em fornecer acessibilidade incomparável. Não importa onde você esteja, o SkipShift garante que você esteja sempre conectado.",
    liquidity_provision_desc: "Segurança e confiança são fundamentais no SkipShift. Embora defendamos a descentralização, também priorizamos a segurança dos nossos usuários. Nossa plataforma integra técnicas avançadas de mitigação de risco, desde contratos inteligentes de garantias que garantem a integridade das transações até garantias que fornecem uma camada adicional de segurança. E no raro caso de disputa? Nosso processo de arbitragem garante uma resolução justa. Com o SkipShift, você não está apenas negociando, você está negociando com confiança.",
    risk_mitigation_desc: "Segurança e confiança são fundamentais no SkipShift. Embora defendamos a descentralização, também priorizamos a segurança dos nossos usuários. Nossa plataforma integra técnicas avançadas de mitigação de risco, desde contratos inteligentes de garantias que garantem a integridade das transações até garantias que fornecem uma camada adicional de segurança. E no raro caso de disputa? Nosso processo de arbitragem garante uma resolução justa. Com o SkipShift, você não está apenas negociando, você está negociando com confiança.",
    toolkit_desc: "Para converter ativos ou obter liquidez, o SkipShift oferece um conjunto de ferramentas completo adaptado às suas necessidades. Os usuários podem incorporar ou retirar ativos sem esforço, enquanto os provedores de liquidez têm à sua disposição um conjunto de ferramentas para avaliar seus ativos. E para empresas ou plataformas que desejam integrar nossos serviços em seus sites, o widget SkipShift oferece serviços financeiros de alto nível com apenas um clique de distância.",
    partners_title: "SkipShift powers the best in web3",
    partners_desc: "Collaboration is at the heart of innovation. At SkipShift, we're proud to partner with industry leaders across various sectors, Dive in and discover the synergies that make SkipShift a market leader.",
    and_more: "...and more!",
    methods_title: 'Use any payment method',
    methods_desc: "Visual clarity is key. Our platform showcases the logos of all supported fiat payment methods and blockchains, offering users a clear snapshot of the myriad options at their fingertips. With SkipShift, choices are not just abundant; they're evident.",
    available_on: "Available on 3+ networks, with more on the way.",
    integrate: "Integrar",
    faq_left_title: "SkipShift: Onde a Fiat encontra a Crypto.",
    faq_left_desc: "SkipShift seamlessly bridges fiat and crypto using decentralized infrastructure. No KYC, no selfies, just effortless conversions with a plethora of global payment methods. Crafted by traders, for traders, it's the future of currency exchange.",
    faq_left_sub_desc: "Dive deep, discover more, and join the SkipShift revolution.",
    faq_right_title: "Perguntas frequentes",
    subscribe_title: 'Inscreva-se em nossa lista de e-mails para se manter atualizado. ',
    subscribe: 'Subscribe'
  },
  'bn': {
    skip_the_questions: 'প্রশ্নগুলো স্কিপ করুন।',
    shift_control: 'নিয়ন্ত্রণ স্থানান্তর করুন।',
    homepage_top_desc: 'স্কিপশিফট (Skipshift) দ্বারা যেকেউ, যেকোন সময়, যেকোন পরিমান এবং যেকোন স্থান থেকে ১০০% বিকেন্দ্রীভুত বা ডিসেন্ট্রালাইজ উপায়ে ক্রিপ্টো থেকে টাকা ও টাকা থেকে ক্রিপ্টোতে রূপান্তর করতে পারবেন।',
    homepage_bottom_desc: 'এর মানে আর কোনো KYC নয়, আর কোনো সেলফি নয়, আর কোনো লেনদেন সীমা নয়।',
    what_weve_built: "আমরা কী তৈরি করেছি",
    products_desc: "SkipShift ফিয়াট এবং ক্রিপ্টোকে সুনিপুণভাবে সংযুক্ত করে, ব্যবহারকারীদের অপ্রয়াসে রূপান্তরের সুযোগ দেয় এবং প্রদানকারীদের প্যাসিভ আয়ের সুযোগ প্রদান করে। প্রোপাইটারি কোড দিয়ে নির্মিত এবং কঠোরভাবে পরীক্ষা করা হয়েছে, এটি একটি আধুনিক প্ল্যাটফর্ম যা ব্যবসায়ীদের দ্বারা, ব্যবসায়ীদের জন্য তৈরি।",
    global_payment_methods: 'বিশ্বব্যাপী পেমেন্ট পদ্ধতি',
    liquidity_provision: 'তরলতা প্রদান',
    risk_mitigation: 'ঝুঁকি হ্রাস',
    toolkit: 'টুলকিট',
    global_payment_methods_desc: "SkipShift-এ, আমরা আমাদের বৈশ্বিক ব্যবহারকারীদের বৈচিত্র্যময় প্রয়োজনীয়তাগুলি বুঝি। তাই আমরা গর্বের সাথে একটি বিশাল পরিসরের পেমেন্ট পদ্ধতি সমর্থন করি, নিশ্চিত করে যে প্রতিটি ব্যবহারকারী তাদের জন্য উপযুক্ত একটি পদ্ধতি খুঁজে পায়। জনপ্রিয় প্ল্যাটফর্মগুলির মতো Paypal, Cashapp, এবং Chime থেকে শুরু করে আন্তর্জাতিক প্রিয় যেমন Revolut, Payoneer, Money Transfer, এবং Western Union পর্যন্ত, আমাদের প্রতিশ্রুতি হল অসামান্য প্রবেশযোগ্যতা প্রদান করা। আপনি যেখানে থাকুন না কেন, SkipShift নিশ্চিত করে যে আপনি সর্বদা সংযুক্ত থাকবেন।",
    liquidity_provision_desc: "তরলতা যে কোনো আর্থিক প্ল্যাটফর্মের জীবনরস, এবং SkipShift-এ, এটি একটি কমিউনিটি-চালিত প্রচেষ্টা। আমাদের অনন্য পদ্ধতি ব্যক্তিদের তরলতা প্রদানকারীর ভূমিকায় প্রবেশ করতে দেয়, নির্দিষ্ট সম্পদের সাথে ট্রেড করতে তাদের প্রস্তুতি সংকেত দেয়। এই বিকেন্দ্রীকৃত পদ্ধতি কেবল ট্রেডিং প্রক্রিয়াকে গণতান্ত্রিকই করে না বরং ব্যবহারকারীদের ট্রেডিং প্রয়োজনীয়তার জন্য সর্বদা একটি ম্যাচ খুঁজে পেতে সহায়তা করে। SkipShift-এ, প্রতিটি লেনদেন কেবল একটি ট্রেড নয়; এটি একটি সংযোগ।",
    risk_mitigation_desc: "SkipShift-এ নিরাপত্তা এবং বিশ্বাস অপরিহার্য। আমরা বিকেন্দ্রীকরণকে সমর্থন করলেও, আমরা আমাদের ব্যবহারকারীদের নিরাপত্তাকেও অগ্রাধিকার দিই। আমাদের প্ল্যাটফর্ম উন্নত ঝুঁকি হ্রাস কৌশলগুলি সংযোজিত করে, লেনদেনের অখণ্ডতা নিশ্চিত করার জন্য এস্ক্রো স্মার্ট চুক্তি থেকে শুরু করে পণায়ন যা একটি অতিরিক্ত স্তরের নিরাপত্তা প্রদান করে। এবং বিরল বিতর্কের ক্ষেত্রে? আমাদের সালিশ প্রক্রিয়া সুষ্ঠু সমাধান নিশ্চিত করে। SkipShift-এ, আপনি কেবল ট্রেডিং করছেন না; আপনি আত্মবিশ্বাসের সাথে ট্রেডিং করছেন।",
    toolkit_desc: "আপনি যদি সম্পদ রূপান্তর করতে বা তরলতা প্রদান করতে চান, SkipShift একটি ব্যাপক টুলকিট প্রদান করে যা আপনার প্রয়োজন অনুসারে প্রস্তুত। ব্যবহারকারীরা সহজেই যেকোনো সম্পদ অনবোর্ড বা অফবোর্ড করতে পারে, এবং প্রদানকারীরা তাদের ধারণাগুলি অর্থায়ন করার জন্য তাদের হাতের তালিকায় একটি টুলসেট পায় এবং ব্যবসা বা প্ল্যাটফর্মগুলির জন্য যারা আমাদের পরিষেবাগুলি একত্রিত করতে চান, SkipShift উইজেট নিশ্চিত করে যে শীর্ষ স্তরের আর্থিক পরিষেবাগুলি একটি ক্লিকে উপলব্ধ।",
    partners_title: "SkipShift এর সেরা ওয়েব3 কে শক্তি দেয়",
    partners_desc: "অবদানই উদ্ভাবনের হৃদয়। SkipShift-এ, আমরা বিভিন্ন খাতে শিল্পের নেতাদের সাথে অংশীদারিত্ব করতে গর্বিত, সহযোগিতার সিঙ্ক্রোনাইজ করুন যা SkipShift-কে একটি বাজার নেতায় পরিণত করে।",
    and_more: "...এবং আরও!",
    methods_title: 'যেকোনো পেমেন্ট পদ্ধতি ব্যবহার করুন',
    methods_desc: "ভিজ্যুয়াল স্বচ্ছতা একটি মূল বিষয়। আমাদের প্ল্যাটফর্ম সমস্ত সমর্থিত ফিয়াট পেমেন্ট পদ্ধতির এবং ব্লকচেইনের লোগোগুলি প্রদর্শন করে, ব্যবহারকারীদের তাদের আঙুলের ডগায় প্রচুর অপশনগুলির একটি পরিষ্কার স্ন্যাপশট প্রদান করে। SkipShift-এ, পছন্দগুলি কেবল প্রচুর নয়; তারা দৃশ্যমান।",
    available_on: "৩+ নেটওয়ার্কে উপলব্ধ, আরও আসছে।",
    integrate: "ইন্টিগ্রেট করুন",
    faq_left_title: "SkipShift: যেখানে ফিয়াট ক্রিপ্টোর সাথে মিলিত হয়",
    faq_left_desc: "SkipShift বিকেন্দ্রীকৃত অবকাঠামো ব্যবহার করে ফিয়াট এবং ক্রিপ্টোকে সুনিপুণভাবে সংযুক্ত করে। কোনো KYC, কোনো সেলফি, কেবল প্রচুর বৈশ্বিক পেমেন্ট পদ্ধতি সহ সহজ রূপান্তর। ব্যবসায়ীদের দ্বারা, ব্যবসায়ীদের জন্য, এটি মুদ্রা বিনিময়ের ভবিষ্যৎ।",
    faq_left_sub_desc: "গভীরে প্রবেশ করুন, আরও আবিষ্কার করুন, এবং SkipShift বিপ্লবে যোগ দিন।",
    faq_right_title: "ঘন ঘন জিজ্ঞাসিত প্রশ্ন",
    subscribe_title: 'আমাদের মেইলিং তালিকায় সাবস্ক্রাইব করুন আপডেট থাকতে।',
    subscribe: 'সাবস্ক্রাইব করুন'
  }
}

export const FAQTexts: IFAQTexts = {
  'en': {
    what_is_title: 'What is SkipShift?',
    what_is_desc: "SkipShift is a groundbreaking product introduced by RBX. It's a fully decentralized peer-to-peer on and off-ramp that facilitates seamless transitions between fiat and cryptocurrency without requiring KYC verification. The entire process is automated, secure, and conducted on-chain.",
    how_to_onramp_title: "How do I start an onramp trade on SkipShift?",
    how_to_onramp_desc: `- To initiate an onramp trade:<br>
    1. Set your wallet network to Avalanche, BSC, or Ethereum.<br>
    2. Navigate to "SkipShift" from the menu.<br>
    3. Connect and verify your wallet to access encryption and chat features.<br>
    4. Click "Start Ramp".<br>
    5. Choose your desired fiat method and amount.<br>
    6. Select the on-chain asset you want.<br>
    7. Provide the payment ID for your chosen payment method.<br>
    8. Submit the trade and confirm the transaction in Metamask.`,
    how_to_offramp_title: "How do I start an offramp trade on SkipShift?",
    how_to_offramp_desc: `- To initiate an offramp trade:<br>
    1. Set your wallet network to Avalanche, BSC, or Ethereum.<br>
    2. Navigate to "SkipShift" from the menu.<br>
    3. Connect and verify your wallet to access encryption and chat features.<br>
    4. Click "Start Ramp".<br>
    5. Choose your desired fiat method and amount.<br>
    6. Select the on-chain asset you want.<br>
    7. Provide the payment ID for your chosen payment method.<br>
    8. Submit the trade and confirm the transaction in Metamask.`,
    collateral_requirements_title: "What are the collateral requirements for users on SkipShift?",
    collateral_requirements_desc: `Most liquidity positions on SkipShift require users to collateralize their trade with 2,500 RBX tokens, unless the liquidity provider disables this requirement. This collateral acts as a "key" to SkipShift access and can be reclaimed after the trade completion.`,
    provider_scoring_title: `How does the provider scoring system work on SkipShift?`,
    provider_scoring_desc: `SkipShift has a built-in provider scoring system. New providers start with a score of 0, which increases with each successful trade. Cancelling an already accepted trade or having arbitration requested for a trade will decrease the score by one point. This system ensures users are matched with the most reliable providers.`,
    face_issues_title: `What happens if I face issues with my trade?`,
    face_issues_desc: `If you encounter problems with your trade, you can refer to the troubleshooting guide or contact us directly through the <a href="https://t.me/skipshift_hub" target="_blank" class="text-sky-300 hover:underline">SkipShift hub</a> or talk to our technology partners over at <a href="https://t.me/RBXTechSupport" target="_blank" class="text-sky-300 hover:underline">RBX Tech Support</a>. If issues persist, you can request arbitration, where a SkipShift or RBX team member will investigate and resolve the dispute.`,
    skipshift_token_title: `Is there a SkipShift token?`,
    skipshift_token_desc: `There have been no official SkipShift tokens released at this time. Any future plans for a token release would be announced through our official channels.`,
  },
  'es': {
    what_is_title: '¿Que es SkipShift?',
    what_is_desc: "SkipShift es un producto revolucionario presentado por RBX. Una plataforma totalmente descentralizada de compra y venta entre pares que facilita transiciones sin problemas entre Fiat y cripto sin requerir verificación de identidad. Todo el proceso es automatizado, seguro y se realiza dentro de la cadena de bloques.",
    how_to_onramp_title: "¿Como comprar cripto en SkipShift?",
    how_to_onramp_desc: `- Para iniciar una operación de compra:<br>
    1. Configura la red de tu cartera en Avalanche, BSC o Ethereum..<br>
    2. Navega hasta "SkipShift" en el menú.<br>
    3. Conecta y verifica tu billetera para acceder a las funciones de encriptación y chat.<br>
    4. Haz clic en "Comprar cripto"..<br>
    5. Elige el método y la cantidad de moneda Fiat deseada.<br>
    6. Selecciona el activo en la cadena que desees.<br>
    7. Proporciona la ID de pago para tu método de pago elegido.<br>
    8. Envía la operación y confirma la transacción en Metamask.`,
    how_to_offramp_title: "¿Como vender cripto en SkipShift?",
    how_to_offramp_desc: `- Para iniciar una operación de venta:<br>
    1. Configura la red de tu cartera en Avalanche, BSC o Ethereum.<br>
    2. Navega hasta "SkipShift" en el menú.<br>
    3. Conecta y verifica tu billetera para acceder a las funciones de encriptación y chat.<br>
    4. Haz clic en "Vender cripto".<br>
    5. Elige el método y la cantidad de moneda Fiat deseada.<br>
    6. Selecciona el activo en la cadena que desees.<br>
    7. Proporciona la ID de pago para tu método de pago elegido.<br>
    8. Envía la operación y confirma la transacción en Metamask.`,
    collateral_requirements_title: "¿Cuales son los requisitos de colateral para los usuarios de SkipShift?",
    collateral_requirements_desc: `La mayoría de las posiciones de liquidez en SkipShift requieren que los usuarios colateralicen su operación con 2,500 tokens RBX, a menos que el proveedor de liquidez desactive este requisito. Este colateral actúa como una "clave" de acceso a SkipShift y puede ser recuperado después de completar la operación.`,
    provider_scoring_title: `¿Como funciona el sistema de puntuación para los proveedores de liquidez?`,
    provider_scoring_desc: `SkipShift cuenta con un sistema integrado de calificación de proveedores. Los nuevos proveedores comienzan con una puntuación de 0, la cual aumenta con cada operación exitosa. Cancelar una operación ya aceptada o tener una solicitud de arbitraje para una operación disminuirá la puntuación en un punto. Este sistema garantiza que los usuarios sean emparejados con los proveedores más confiables.`,
    face_issues_title: `¿ Que pasa si tengo algún problema durante mi transacción?`,
    face_issues_desc: `Si encuentras problemas con tu operación, puedes consultar la guía de solución de problemas o contactarnos directamente a través del hub de SkipShift o hablar con nuestros socios 	tecnológicos en RBX soporte tecnico Si los problemas 	persisten, puedes solicitar arbitraje, donde un miembro del equipo de SkipShift o RBX investigará y resolverá la disputa. <a href="https://t.me/skipshift_hub" target="_blank" class="text-sky-300 hover:underline">SkipShift hub</a>`,
    skipshift_token_title: `¿Hay un token de SkipShift?`,
    skipshift_token_desc: `Hasta el momento, no se han lanzado ningun token oficial de SkipShift. Cualquier plan futuro para el lanzamiento de un token se anunciaría a través de nuestros canales oficiales.`,
  },
  'cz': {
    what_is_title: 'What is SkipShift?',
    what_is_desc: "SkipShift is a groundbreaking product introduced by RBX. It's a fully decentralized peer-to-peer on and off-ramp that facilitates seamless transitions between fiat and cryptocurrency without requiring KYC verification. The entire process is automated, secure, and conducted on-chain.",
    how_to_onramp_title: "How do I start an onramp trade on SkipShift?",
    how_to_onramp_desc: `- To initiate an onramp trade:<br>
    1. Set your wallet network to Avalanche, BSC, or Ethereum.<br>
    2. Navigate to "SkipShift" from the menu.<br>
    3. Connect and verify your wallet to access encryption and chat features.<br>
    4. Click "Start Ramp".<br>
    5. Choose your desired fiat method and amount.<br>
    6. Select the on-chain asset you want.<br>
    7. Provide the payment ID for your chosen payment method.<br>
    8. Submit the trade and confirm the transaction in Metamask.`,
    how_to_offramp_title: "How do I start an offramp trade on SkipShift?",
    how_to_offramp_desc: `- To initiate an offramp trade:<br>
    1. Set your wallet network to Avalanche, BSC, or Ethereum.<br>
    2. Navigate to "SkipShift" from the menu.<br>
    3. Connect and verify your wallet to access encryption and chat features.<br>
    4. Click "Start Ramp".<br>
    5. Choose your desired fiat method and amount.<br>
    6. Select the on-chain asset you want.<br>
    7. Provide the payment ID for your chosen payment method.<br>
    8. Submit the trade and confirm the transaction in Metamask.`,
    collateral_requirements_title: "What are the collateral requirements for users on SkipShift?",
    collateral_requirements_desc: `Most liquidity positions on SkipShift require users to collateralize their trade with 2,500 RBX tokens, unless the liquidity provider disables this requirement. This collateral acts as a "key" to SkipShift access and can be reclaimed after the trade completion.`,
    provider_scoring_title: `How does the provider scoring system work on SkipShift?`,
    provider_scoring_desc: `SkipShift has a built-in provider scoring system. New providers start with a score of 0, which increases with each successful trade. Cancelling an already accepted trade or having arbitration requested for a trade will decrease the score by one point. This system ensures users are matched with the most reliable providers.`,
    face_issues_title: `What happens if I face issues with my trade?`,
    face_issues_desc: `If you encounter problems with your trade, you can refer to the troubleshooting guide or contact us directly through the <a href="https://t.me/skipshift_hub" target="_blank" class="text-sky-300 hover:underline">SkipShift hub</a> or talk to our technology partners over at <a href="https://t.me/RBXTechSupport" target="_blank" class="text-sky-300 hover:underline">RBX Tech Support</a>. If issues persist, you can request arbitration, where a SkipShift or RBX team member will investigate and resolve the dispute.`,
    skipshift_token_title: `Is there a SkipShift token?`,
    skipshift_token_desc: `There have been no official SkipShift tokens released at this time. Any future plans for a token release would be announced through our official channels.`,
  },
  'pt': {
    what_is_title: 'O que é SkipShift?',
    what_is_desc: "SkipShift é um produto revolucionário introduzido pela RBX. Uma plataforma de compra e venda ponto a ponto totalmente descentralizada que facilita transições perfeitas entre Fiat e criptografia sem exigir verificação de identidade. Todo o processo é automatizado, seguro e realizado dentro do blockchain.",
    how_to_onramp_title: "Como comprar cripto em SkipShift?",
    how_to_onramp_desc: `- Para iniciar uma operação de compra:<br>
    1. Defina a rede da sua carteira como Avalanche, BSC ou Ethereum<br>
    2. Navegue até “SkipShift” no menu<br>
    3. Conecte e verifique sua carteira para acessar recursos de criptografia e bate-papo.<br>
    4. Clique em “Comprar criptografia”. Escolha o método e a quantidade de moeda Fiat desejada<br>
    5. Escolha o método e a quantidade de moeda Fiat desejada<br>
    6. Selecione o ativo na cadeia que você deseja. <br>
    7. Forneça o ID de pagamento da forma de pagamento escolhida. <br>
    8. Envie a operação e confirme a transação no Metamask.`,
    how_to_offramp_title: "Como vender criptografia no SkipShift?",
    how_to_offramp_desc: `- Para iniciar uma negociação de venda<br>
    1. Defina sua rede de carteira como Avalanche, BSC ou Ethereum.<br>
    2. Navegue até “SkipShift” no menu.<br>
    3. Conecte e verifique sua carteira para acessar recursos de criptografia e bate-papo.<br>
    4. Clique em “Vender criptografia”.<br>
    5. Escolha o método e a quantidade de moeda Fiat desejada.<br>
    6. Selecione o ativo na cadeia que você deseja. <br>
    7. Forneça o ID de pagamento da forma de pagamento escolhida. <br>
    8. Envie a operação e confirme a transação no Metamask.`,
    collateral_requirements_title: "Quais são os requisitos colaterais para usuários do SkipShift?",
    collateral_requirements_desc: `A maioria das posições de líquidez no SkipShift exige que os usuários garantam suas negociações com 2.500 tokens RBX, a menos que este requisito seja desabilitado pelo provedor de liquidez. Essa garantia funciona como uma “chave” de acesso ao SkipShift e pode ser recuperada após a conclusão da operação.`,
    provider_scoring_title: `Como funciona o sistema de pontuação para provedores de liquidez? `,
    provider_scoring_desc: `SkipShift possui um sistema integrado de classificação de fornecedores. Novos fornecedores começam com pontuação 0, que aumenta a cada transação bem-sucedida. Cancelar uma negociação já aceita ou solicitar arbitragem para uma negociação diminuirá a pontuação em um ponto. Este sistema garante que os usuários encontrem os fornecedores mais confiáveis.`,
    face_issues_title: `O que acontece se eu tiver um problema durante minha transação?`,
    face_issues_desc: `Se você encontrar problemas com sua operação, você pode consultar o guia de solução de problemas ou entrar em contato conosco diretamente através do  <a href="https://t.me/skipshift_hub" target="_blank" class="text-sky-300 hover:underline">hub SkipShift</a> ou falar com nossos parceiros de <a href="https://t.me/RBXTechSupport" target="_blank" class="text-sky-300 hover:underline">tecnologia no suporte técnico RBX</a>. Se os problemas persistirem, você pode solicitar arbitragem, onde um membro da equipe SkipShift o A RBX investigará e resolverá a disputa.`,
    skipshift_token_title: `Existe um token SkipShift? `,
    skipshift_token_desc: `Até agora, nenhum token oficial do SkipShift foi lançado. Quaisquer planos futuros para o lançamento de token serão anunciados através de nossos canais oficiais. Inscreva-se em nossa lista de e-mails para se manter atualizado.`,
  },
  'bn': {
    what_is_title: 'SkipShift কি?',
    what_is_desc: "SkipShift একটি নতুন প্লাটফর্ম যা RBX দ্বারা উপস্থাপিত হয়েছে। এটি একটি সম্পূর্ণভাবে বিকেন্দ্রীভূত পিয়ার-টু-পিয়ার (P2P) অন এবং অফ-রাম্প যা KYC যাচাইকরণের প্রয়োজন ছাড়াই ফিয়াট এবং ক্রিপ্টোকারেন্সির মধ্যে দ্বিমুখী ট্রানজিশনকে সহজতর করে। পুরো প্রক্রিয়াটি স্বয়ংক্রিয়, নিরাপদ এবং ব্লকচেইনে সম্পন্ন হয়।",
    how_to_onramp_title: "আমি কিভাবে SkipShift-এ অনরাম্প ট্রেড শুরু করব?",
    how_to_onramp_desc: `- অনরাম্প ট্রেড শুরু করতে: <br>
    1. আপনার ওয়ালেটর নেটওয়ার্ককে Avalanche, BSC, বা Ethereum ব্লকচেইনে সেট করুন।<br>
    2. মেনু থেকে "SkipShift" এ যান।<br>
    3. এনক্রিপশন ও চ্যাট ফিচারগুলির অ্যাক্সেস পেতে আপনার ওয়ালেট সংযুক্ত বা কানেক্ট করুন এবং সঠিক হয়েছে কিনা যাচাই করুন।<br>
    4. "Start Ramp" ক্লিক করুন।<br>
    5. আপনার পছন্দের ফিয়াট কারেন্সির মেথড এবং পরিমাণ নির্ধারণ করুন।<br>
    6. আপনি যে অন - চেইন অ্যাসেটটি বা ক্রিপ্টোকারেন্সি চান তা নির্বাচন করুন।<br>
    7. আপনার নির্বাচিত পেমেন্ট পদ্ধতির জন্য পেমেন্ট আইডি প্রদান করুন।<br>
    8. ট্রেড সাবমিট করুন এবং Metamask - এ লেনদেন নিশ্চিত করুন।.`,
    how_to_offramp_title: "আমি কিভাবে SkipShift-এ অফরাম্প ট্রেড শুরু করব?",
    how_to_offramp_desc: `- অফরাম্প ট্রেড শুরু করতে: <br>
    1. আপনার ওয়ালেটর নেটওয়ার্ককে Avalanche, BSC, বা Ethereum এ ব্লকচেইনে সেট করুন।<br>
    2. মেনু থেকে "SkipShift" এ যান।<br>
    3. এনক্রিপশন ও চ্যাট ফিচারগুলির অ্যাক্সেস পেতে আপনার ওয়ালেট সংযুক্ত করুন এবং যাচাই করুন।<br>
    4. "Start Ramp" ক্লিক করুন।<br>
    5. আপনার কাঙ্ক্ষিত ফিয়াট পদ্ধতি এবং পরিমাণ নির্বাচন করুন।<br>
    6. আপনি যে অন - চেইন অ্যাসেটটি চান তা নির্বাচন করুন।<br>
    7. আপনার নির্বাচিত পেমেন্ট পদ্ধতিটির পেমেন্ট আইডি প্রদান করুন।<br>
    8. ট্রেড জমা দিন এবং Metamask - থেকে লেনদেন নিশ্চিত করুন।`,
    collateral_requirements_title: "SkipShift ব্যবহারকারীদের জন্য জামানতের প্রয়োজনীয়তা কি?",
    collateral_requirements_desc: "SkipShift বেশিরভাগ লিকুইডিটি প্রোভাইডার তাদের ট্রেডের জন্য ২,৫০০ RBX টোকেন জামানত হিসেবে দিতে হয়, যদি না লিকুইডিটি প্রদানকারী এই প্রয়োজনীয়তা অক্ষম করে। এই জামানত SkipShift অ্যাক্সেসের জন্য একটি \"চাবি\" হিসেবে কাজ করে এবং ট্রেড সম্পন্ন হওয়ার পরে পুনরুদ্ধার করা যেতে পারে।",
    provider_scoring_title: "SkipShift-এ প্রদানকারী স্কোরিং সিস্টেম কিভাবে কাজ করে?",
    provider_scoring_desc: "SkipShift-এ একটি বিল্ট-ইন প্রদানকারী স্কোরিং সিস্টেম রয়েছে। নতুন প্রদানকারীরা ০ স্কোর নিয়ে শুরু করেন, যা প্রতিটি সফল ট্রেডের সাথে বৃদ্ধি পায়। ইতিমধ্যে গৃহীত ট্রেড বাতিল করা বা ট্রেড থেকে কোন রিপোর্ট করা হয় তাহলে স্কোর থেকে এক পয়েন্ট কমে যায়। এই সিস্টেম নিশ্চিত করে যে ব্যবহারকারীরা সবচেয়ে নির্ভরযোগ্য প্রদানকারীদের সাথে মেলানো হয়।",
    face_issues_title: "যদি আমার ট্রেড সমস্যার সম্মুখীন হয় তাহলে কি হবে?",
    face_issues_desc: "যদি আপনার ট্রেড সমস্যার সম্মুখীন হন, আপনি সমস্যা সমাধানের গাইডলাইন দেখতে পারেন অথবা আমাদের সাথে সরাসরি যোগাযোগ করতে পারেন <a href=\"https://t.me/skipshift_hub\" target=\"_blank\" class=\"text-sky-300 hover:underline\">SkipShift হাব</a> অথবা আমাদের টীম মেম্বারদের সাথে কথা বলতে পারেন <a href=\"https://t.me/RBXTechSupport\" target=\"_blank\" class=\"text-sky-300 hover:underline\">RBX টেক সাপোর্ট</a>। যদি সমস্যা চলতে থাকে, আপনি সালিশি অনুরোধ করতে পারেন, যেখানে একটি SkipShift বা RBX দলের সদস্য তদন্ত করে বিতর্ক সমাধান করবেন।",
    skipshift_token_title: "SkipShift এর কি টোকেন আছে?",
    skipshift_token_desc: "এখন পর্যন্ত কোন আনুষ্ঠানিক SkipShift টোকেন প্রকাশ করা হয়নি। ভবিষ্যতে টোকেন প্রকাশের কোন পরিকল্পনা থাকলে আমাদের আনুষ্ঠানিক চ্যানেলগুলির মাধ্যমে ঘোষণা করা হবে।",
  }
}

export const ModalTexts: IModalTexts = {
  'en': {
    alerts_modal_title: "Sign up for Direct Alerts!",
    alerts_modal_desc: "Click the blue button below to be taken directly to our Telegram notification bot.",
    alerts_modal_confirm: "let's go!"
  },
  'es': {
    alerts_modal_title: "¡Regístrate para recibir Alertas Directas!",
    alerts_modal_desc: "Haz clic en el botón azul de abajo para ir directamente a nuestro bot de notificaciones de Telegram.",
    alerts_modal_confirm: "¡Vamos!"
  },
  'cz': {
    alerts_modal_title: "Sign up for Direct Alerts!",
    alerts_modal_desc: "Click the blue button below to be taken directly to our Telegram notification bot.",
    alerts_modal_confirm: "let's go!"
  },
  'pt': {
    alerts_modal_title: "Sign up for Direct Alerts!",
    alerts_modal_desc: "Click the blue button below to be taken directly to our Telegram notification bot.",
    alerts_modal_confirm: "let's go!"
  },
  'bn': {
    alerts_modal_title: "সরাসরি সতর্কতার জন্য সাইন আপ করুন!",
    alerts_modal_desc: "আমাদের টেলিগ্রাম বিজ্ঞপ্তি বটে সরাসরি যেতে নিচের নীল বোতামে ক্লিক করুন।",
    alerts_modal_confirm: "চল যাই!"
  },
}

export const TradeActionButtonTexts: ITradeActionButtonTexts = {
  'en': {
    waiting: "Waiting...",
    accept: "Accept trade",
    rejected: "Trade Rejected",
    conf_number: "Conf. #",
    complete: "Complete",
    trade_complete: "Trade complete",
    cancelled: "Cancelled"
  },
  'es': {
    waiting: "Esperando...",
    accept: "Aceptar",
    rejected: "Rechazado",
    conf_number: "Conf. #",
    complete: "Completar",
    trade_complete: "Completado",
    cancelled: "Cancelado"
  },
  'cz': {
    waiting: "Waiting...",
    accept: "Accept trade",
    rejected: "Trade Rejected",
    conf_number: "Conf. #",
    complete: "Complete",
    trade_complete: "Trade complete",
    cancelled: "Cancelled"
  },
  'pt': {
    waiting: "Waiting...",
    accept: "Accept trade",
    rejected: "Trade Rejected",
    conf_number: "Conf. #",
    complete: "Complete",
    trade_complete: "Trade complete",
    cancelled: "Cancelled"
  },
  'bn': {
    waiting: "অপেক্ষা করছে...",
    accept: "লেনদেন গ্রহণ করুন",
    rejected: "লেনদেন প্রত্যাখ্যাত",
    conf_number: "কনফ. #",
    complete: "সম্পূর্ণ",
    trade_complete: "লেনদেন সম্পন্ন",
    cancelled: "বাতিল"
  }
}